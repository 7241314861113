<template>
  <div class="protospellings" id="select-protospelling">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'
  import $ from 'jquery'

  export default {
    name: 'ProtospellingSelect',
    props: ['exclude', 'protospelling'],
    components: {
      Select2
    },
    data() {
      return {
        query: ''
      }
    },
    mounted() {
      if (this.protospelling){
        this.$refs.select2.select2.append(
          `<option selected value="${this.protospelling.id}">${this.protospelling.spelling}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a protospelling",
          // allowClear: true,
          width: '100%',
          templateResult: this.formatOption,
          dropdownParent: "#select-protospelling",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/protospellings/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              this.query = params.term
              return {
                query: params.term,
                exclude: JSON.stringify(this.exclude),
              }
            },
            processResults: (data) => {
              data.push({
                id: -2,
                spelling: `Create new <b>${this.query}</b>`
              })
              return {
                results: data.map(protospelling => {
                  return {
                    id: protospelling.id,
                    text: protospelling.spelling,
                    protospelling: protospelling
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        if (event.selected && event.id == -2){
          this.$emit('selectNew', this.query)
        }
        else{
          this.$emit('select', event.selected ? event.protospelling : null)
        }
      },
      formatOption (item) {
        let text = item.text.trim() ? item.text : item.id
        return $(`<span>${text}</span>`)
      }
    }
  }
</script>
