<template>
  <div>
    <span v-if="(disableAdd != true || disableEdit != true) && config.editable">
      <span v-if="editTree">
        <button v-if="disableAdd != true" @click="addNewNode" class="btn-legam-xs mb-2 mr-3">
          <b-icon icon="plus"></b-icon>
          Add
        </button>
        <button @click="saveTree" class="btn-legam-xs mb-2 btn-primary">
          <b-icon icon="lock-fill"></b-icon>
          Save tree
        </button>
        <button @click="cancelEditTree" class="btn-legam-xs mb-2">
          Cancel
        </button>
      </span>
      <span v-else-if="config.editable">
        <button @click="startEditTree" class="btn-legam-xs mb-2">
          <b-icon icon="unlock-fill"></b-icon>
          Unlock tree
        </button>
      </span>
    </span>
    <VueTreeList
      :model="nodes"
      :editMode="editTree && disableEdit != true"
      :maxDepth="maxDepth"
      v-bind:default-expanded="true"
    >
      <template v-slot:leafNameDisplay="slotProps">
        <slot name="leafNameDisplay" v-bind="slotProps" />
      </template>
      <span class="icon" slot="treeNodeIcon">{{ bullet }}</span>
      <span class="icon" slot="leafNodeIcon">{{ bullet }}</span>
      <!-- <span class="icon" slot="treeNodeIcon">●</span> -->
      <!-- <span class="icon" slot="addTreeNodeIcon">📂</span>
      <span class="icon" slot="addLeafNodeIcon">＋</span>
      <span class="icon" slot="editNodeIcon">📃</span>
      <span class="icon" slot="delNodeIcon">✂️</span>
      <span class="icon" slot="leafNodeIcon">🍃</span>
      <span class="icon" slot="treeNodeIcon">🌲</span> -->
    </VueTreeList>
    <!-- <button @click="getNewTree">Get new tree</button>
    <pre>
      {{newTree}}
    </pre> -->
  </div>
</template>

<style>
  .vtl-node-content {
    padding-left: 0.3em;
  }

  .vtl-caret {
    margin-bottom: -4px;
  }

  .vtl-caret .vtl-icon {
    opacity: 0.2;
  }

  .icon {
    opacity: 0.6;
  }

  .vtl {
    cursor: pointer;
  }

  .vtl .vtl-drag-disabled:hover {
    background-color: #e7e7e7;
  }

  .vtl-tree-margin {
    margin-left: 1.3em;
  }

  .vtl .vtl-disabled {
    background-color: #d0cfcf;
  }

  .icon:hover {
    cursor: pointer;
  }

  .muted {
    color: gray;
    font-size: 80%;
  }

  .vtl-operation {
    display: none;
  }
</style>

<script>
  import VueTreeList from '@/components/vue-tree-list/VueTreeList.vue'
  import { Tree } from '@/components/vue-tree-list/Tree.js'
  import config from '@/config.js'

  export default {
    name: 'TreeView',
    props: ['data', 'bullet', 'treeMaxDepth', 'treeMaxRoots', 'disableEdit', 'disableAdd'],
    components: {
      VueTreeList
    },
    data(){
      return {
        config: config,
        nodes: new Tree(this.data),
        treeData: this.data,
        editTree: false,
        maxDepth: this.treeMaxDepth ? this.treeMaxDepth : -1,
        maxRoots: this.treeMaxRoots ? this.treeMaxRoots : -1,
      }
    },
    watch: {
      data: function(newData){
        this.treeData = newData
        this.nodes = new Tree(newData)
      }
    },
    methods: {
      startEditTree(){
        this.editTree = true
      },
      cancelEditTree(){
        this.nodes = new Tree(this.treeData)
        this.editTree = false
        this.$emit('cancel')
      },
      saveTree(){
        if (this.maxRoots > 0 && this.getNewTree().children.length > this.maxRoots){
          this.$store.dispatch('addNotification', {
            type: 'warning',
            text: `Only ${this.maxRoots} root node is allowed`
          })
          return;
        }
        this.treeData = this.getNewTree().children
        this.$emit('save', this.treeData)
        this.editTree = false
      },
      addNewNode(){
        this.$emit('add')
      },
      getNewTree() {
        function _dfs(treeNode) {
          var newNode = {
            id: treeNode.id,
            name: treeNode.name,
            children: []
          }

          if (treeNode.children && treeNode.children.length > 0) {
            newNode.children = []
            for (var i = 0, len = treeNode.children.length; i < len; i++) {
              newNode.children.push(_dfs(treeNode.children[i]))
            }
          }
          return newNode
        }

        return _dfs(this.nodes)
      }
    }
  }
</script>
