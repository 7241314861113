<template>
  <div class="clearfix">
    <table class="table" :id="'datatable-' + tableUUID">
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.key" v-html="column.name"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in occurrences" :key="row.id" :data-id="row.id" :data-type="row._occurrenceType">
          <td>
            <span v-if="row.externalEvidence">
              <span class="d-none" v-html="normalizeString(row.externalEvidence.surfaceForm)"></span>
              <span
                v-if="row.externalEvidence.surfaceForm"
                v-html="row.externalEvidence.surfaceForm"
              ></span>
            </span>
            <span v-else-if="row.token">
              <span class="d-none" v-html="normalizeString(row.token.form)"></span>
              <span v-html="row.token.form"></span>
            </span>
            <span v-else>
              <span class="d-none" v-html="normalizeString(row.surfaceForm)"></span>
              {{ row.surfaceForm }}
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span class="d-none" v-html="row.externalEvidence.calcDateNumText"></span>
              <span
                v-if="row.externalEvidence.calcDateSpecText"
                v-html="row.externalEvidence.calcDateSpecText"
              ></span>
              <span v-else v-html="row.externalEvidence.calcDateNumText"></span>
            </span>
            <span v-else-if="row.token">
              <span class="d-none" v-if="row.token.document" v-html="row.token.document.calcDateNumText"></span>
              <span
                v-if="row.token.document && row.token.document.calcDateSpecText"
                v-html="row.token.document.calcDateSpecText"
              ></span>
              <span v-else-if="row.externalEvidence" v-html="row.externalEvidence.calcDateNumText"></span>
            </span>
            <span v-else>
              <span class="d-none" v-html="row.dateNum"></span>
              <span v-html="row.dateSpec"></span>
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span class="d-none" v-html="row.externalEvidence.calcDateNumManuscript"></span>
              <span
                v-if="row.externalEvidence.calcDateSpecManuscript"
                v-html="row.externalEvidence.calcDateSpecManuscript"
              ></span>
              <span v-else v-html="row.externalEvidence.calcDateNumManuscript"></span>
            </span>
            <span v-else-if="row.token">
              <span class="d-none" v-html="row.token.document.calcDateNumManuscript"></span>
              <span
                v-if="row.token.document.calcDateSpecManuscript"
                v-html="row.token.document.calcDateSpecManuscript"
              ></span>
              <span v-else v-html="row.token.document.calcDateNumManuscript"></span>
            </span>
            <span v-else>
              -
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span
                v-if="row.externalEvidence.calcTextLocation"
                v-html="row.externalEvidence.calcTextLocation"
              ></span>
            </span>
            <span v-else-if="row.token">
              <span
                v-if="row.token.document && row.token.document.calcTextLocation"
                v-html="row.token.document.calcTextLocation"
              ></span>
            </span>
            <span v-else>
              <span
                v-if="row.location"
                v-html="row.location.display"
              ></span>
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span
                v-if="row.externalEvidence.calcManuscriptLocation"
                v-html="row.externalEvidence.calcManuscriptLocation"
              ></span>
            </span>
            <span v-else-if="row.token">
              <span
                v-if="row.token.document && row.token.document.calcManuscriptLocation"
                v-html="row.token.document.calcManuscriptLocation"
              ></span>
            </span>
            <span v-else>
              -
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span v-if="row.externalEvidence.edition"
                class="d-none"
                v-html="normalizeString(row.externalEvidence.edition.sigleLegam)"></span>
              <span
                v-if="row.externalEvidence.edition"
                v-html="row.externalEvidence.edition.sigleLegam"
              ></span>
            </span>
            <span v-else-if="row.token">
              <span class="d-none" v-html="normalizeString(row.token.document.edition.sigleLegam)"></span>
              <span
                v-if="row.token.document && row.token.document.edition"
                v-html="row.token.document.edition.sigleLegam"
              ></span>
            </span>
            <span v-else>
              <span class="d-none" v-html="normalizeString(row.siglum)"></span>
              <span v-html="row.siglum"></span>
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span
                v-if="row.externalEvidence.position"
                v-html="row.externalEvidence.position"
              ></span>
            </span>
            <span v-else-if="row.token">
              <span
                v-if="row.token.document && row.token.division"
                v-html="row.token.division"
              ></span>
            </span>
            <span v-else>
              <span
                v-if="row.position"
                v-html="row.position"
              ></span>
            </span>
          </td>
          <td>
            <span v-if="row.externalEvidence">
              <span
                v-if="row.externalEvidence.context"
                v-html="row.externalEvidence.context"
              ></span>
            </span>
            <span v-else-if="row.token">
              <span
                v-if="row.token.document"
                v-html="row.token.document.content"
              ></span>
            </span>
            <span v-else>
              <span v-html="row.context"></span>
            </span>
          </td>
          <td v-if="showComments">
            <span v-html="row.comment"></span>
          </td>
          <td>
            <div class="text-right">
              <a href="#" class="mr-1 showDetailsModal">
                <b-icon icon="search"></b-icon>
              </a>
              <router-link
                v-if="row._occurrenceType == 'occurrence' || row._occurrenceType == 'containeroccurrence'"
                :to="{name: 'OccurrenceDetails', params: {id: row.id}}"
                target="_blank"
              >
                <b-icon icon="link45deg"></b-icon>
              </router-link>
              <router-link
                v-else
                :to="{ name: 'ReferenceDetails', params: {id: row.id}}"
                target="_blank"
              >
                <b-icon icon="link45deg"></b-icon>
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
      </tfoot>
    </table>

    <b-button v-if="selected.length && disableEdit != true" @click="addTo" size="sm">Add to {{ type }}</b-button>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
    white-space: nowrap;
  }

  * >>> .highlight {
    background-color: #1e9988;
    color: #fff;
  }

  * >>> table tbody tr.selected td {
    background-color: rgb(4 123 254 / 8%);
  }

  * >>> table tr[data-type="reference"] {
    background-color: #c2d2b25c;
  }

  table.dataTable > thead .sorting:before,
  table.dataTable > thead .sorting_asc:before,
  table.dataTable > thead .sorting_desc:before,
  table.dataTable > thead .sorting_asc_disabled:before,
  table.dataTable > thead .sorting_desc_disabled:before,
  table.dataTable > thead .sorting:after,
  table.dataTable > thead .sorting_asc:after,
  table.dataTable > thead .sorting_desc:after,
  table.dataTable > thead .sorting_asc_disabled:after,
  table.dataTable > thead .sorting_desc_disabled:after {
    font-size: 13px;
  }
</style>

<script>
  import 'jquery/dist/jquery.min.js';
  import 'datatables.net-bs4'
  import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
  import Utils from '@/utils.js'
  import $ from 'jquery'
  import '@/utilities/datatables-pagination.js'
  import '@/utilities/datatables-pagination.css'

  export default {
    name: 'OccurrencesTableView',
    props: ['occurrences', 'showTotal', 'selectedOccurrences', 'type', 'showComments', 'allowSelection', 'disableEdit'],
    data(){
      let columns = [
        { key: 'form', name: 'Form', sortable: true },
        { key: 'dateText', name: 'Date Text', sortable: true },
        { key: 'dateMs', name: 'Date MS', sortable: true },
        { key: 'localizationText', name: 'Loc. Text', sortable: true },
        { key: 'localizationMs', name: 'Loc. MS', sortable: true },
        { key: 'sigle', name: 'Sigle', sortable: true },
        { key: 'position', name: 'Position', sortable: true },
        { key: 'context', name: 'Context', sortable: true }
      ]
      if (this.showComments){
        columns.push({ key: 'comment', name: 'Comment', sortable: true })
      }
      columns.push({ key: 'action', name: '-', sortable: false })

      return {
        tableUUID: Utils.uuidv4(),
        table: null,
        selected: this.selectedOccurrences || [],
        columns: columns
      }
    },
    mounted(){
      let that = this

      this.table = $('table#datatable-' + this.tableUUID).DataTable({
        language: {
          info: "_START_-_END_ of _TOTAL_",
          infoFiltered: " - filtered from _MAX_"
        },
        lengthMenu: [25, 50, 100, 200],
        dom: "rlt<'row'<'col-sm-12 col-md-4'i><'col-sm-12 col-md-8'p>>",
        pageLength: 25,
        pagingType: "input",
        stateSave: true,
        select: true,
        columns: this.columns.map(column => {
          return {
            data: column.name,
            defaultContent: '',
            orderable: (column.sortable === true ? true : false)
          }
        })
      })


      $(`table#datatable-${this.tableUUID}`).on('click', 'tbody tr td a.showDetailsModal', function(event){
        event.preventDefault()
        let dataId = $(event.currentTarget).parents('tr').data('id')
        var occurrence_reference = that.occurrences.filter(occurrence_reference => occurrence_reference.id == dataId)[0]
        that.$emit('showOccurrenceReferenceDetails', occurrence_reference)
      })

      if (this.allowSelection){
        $('table#datatable-' + this.tableUUID).on('click', 'tbody tr td:not(:last-child)', function(el) {
          let trElement = $(el.currentTarget).parents('tr')
          let dataId = trElement.data('id')
          var data = that.occurrences.filter(occurrence_reference => occurrence_reference.id == dataId)[0]
          // if (that.selectedRows != undefined && $(el.currentTarget).hasClass('selectable')){
          if (that.selected != undefined){
            if (trElement.hasClass('selected')){
              that.selected = that.selected.filter(x => x != data.id)
            }
            else {
              that.selected.push(data.id)
            }
            trElement.toggleClass('selected')
          }
          that.$emit('selected', that.selected)
        })
      }
    },
    methods: {
      normalizeString(value){
        return Utils.normalizeString(value)
      },
      addTo(){
        this.$emit('addTo')
      },
      htmlSubstring(s, n) {
        var m, r = /<([^>\s]*)[^>]*>/g,
          stack = [],
          lasti = 0,
          result = '';

        //for each tag, while we don't have enough characters
        while ((m = r.exec(s)) && n) {
          //get the text substring between the last tag and this one
          var temp = s.substring(lasti, m.index).substr(0, n)
          //append to the result and count the number of characters added
          result += temp
          n -= temp.length
          lasti = r.lastIndex

          if (n) {
            result += m[0];
            if (m[1].indexOf('/') === 0) {
              //if this is a closing tag, than pop the stack (does not account for bad html)
              stack.pop();
            } else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
              //if this is not a self closing tag than push it in the stack
              stack.push(m[1]);
            }
          }
        }

        //add the remainder of the string, if needed (there are no more tags in here)
        result += s.substr(lasti, n);

        //fix the unclosed tags
        while (stack.length) {
          result += '</' + stack.pop() + '>';
        }

        return result
      }
    }
  }
</script>
