<template>
  <div class="protospelling-box">
    <!-- <h6 class="mt-4 legam-h6">
      Redacted protospellings
    </h6> -->
    <b-tabs content-class="mt-3" class="legam-tree-list-tabs no-mt">
      <b-tab :title="this.$t('Tree')" active @click="tabChange('tree')">
        <span v-if="currentTab == 'tree'">
          <TreeView
            :data="treeForProtospelling"
            :bullet="'-'"
            @save="saveTree"
            @add="showAddNewProtospelling"
            v-if="protospellingTree && Object.values(protospellingTree.children).length"
            :disableEdit="!$store.getters.checkRole('legam.dict.lemma.treerearrange')"
            :disableAdd="!$store.getters.checkRole('legam.dict.lemma.treerearrange')"
          >
            <template v-slot:leafNameDisplay="slotProps">
              <span v-html="slotProps.model.name"></span>
              <span class="contextmenu" v-if="$store.getters.checkRole('legam.dict.lemma.treerearrange')">
                <button class="btn-legam-tooltip" @click="deleteProtospelling(slotProps.model.id)">
                  <b-icon icon="trash-fill"></b-icon>
                  Remove
                </button>
              </span>
            </template>
          </TreeView>
          <span v-else-if="config.editable">
            <span class="btn-legam-xs btn-legam-xxs ml-2" @click="showAddNewProtospelling">
              <b-icon icon="plus"></b-icon>
              Add
            </span>
          </span>
        </span>
      </b-tab>
      <b-tab :title="$t('List')" @click="tabChange('list')">
        <span v-if="currentTab == 'list'">
          <ul
            class="protospelling-ul"
            v-if="protospellingTree && Object.values(protospellingTree.children).length"
            :class="ulSortType"
          >
            <li
              v-for="protospelling in combinedProtospellingsData"
              :key="protospelling.id"
              class="tooltip-box tooltip-box-w110"
              :class="(protospelling._details) ? 'edit-hover' : ''"
              :data-tree-level="protospelling.level"
            >
              <div class="li-content">
                ● <ProtospellingDisplay :protospelling="protospelling" />

                <div class="ml-3" v-for="surfaceForm in Object.keys(protospelling.occurrencesSurfaceFormGrouped).sort(sortHelper)" :key="surfaceForm">
                  ○ <span v-html="surfaceForm"></span>&nbsp;
                  <span class="occurrences">
                    <span class="occurrence" v-for="occurrence in protospelling.occurrencesSurfaceFormGrouped[surfaceForm].filter(occurrence => (occurrence._occurrenceType == 'occurrence' || occurrence._occurrenceType == 'containeroccurrence'))" :key="occurrence.id">
                      <span v-if="occurrence.suppressed">°</span>
                      <OccurrenceDisplay :occurrence="occurrence" :showForm="false" type="protospelling" />
                    </span>
                  </span>
                  <span v-if="protospelling.occurrencesSurfaceFormGrouped[surfaceForm].filter(occurrence => occurrence._occurrenceType == 'reference').length" class="references">
                    <sup><small>REF</small></sup>
                    <!-- <hr class="mt-1 mb-1"> -->
                    <span class="references-box">
                      <span class="reference" v-for="reference in protospelling.occurrencesSurfaceFormGrouped[surfaceForm].filter(occurrence => occurrence._occurrenceType == 'reference')" :key="reference.id">
                        &nbsp;
                        <span v-if="reference.suppressed">°</span>
                        <ReferenceDisplay :reference="reference" :showForm="false" />
                      </span>
                    </span>
                  </span>
                </div>
                <span class="contextmenu">
                  <button class="btn-legam-tooltip" @click="showProtospellingDetails($event, protospelling)" v-if="disableEdit != true || !config.editable">
                    <b-icon icon="eye"></b-icon>
                    Show details
                  </button>
                  <router-link :to="{name: 'ProtospellingDetails', params: {id: protospelling.id}}" target="_blank" class="btn-legam-tooltip">
                    <b-icon icon="search"></b-icon>
                    Protospelling Details<span v-if="config.editable">/Edit</span>
                  </router-link>
                </span>
              </div>
              <div class="edit-box-content">
                <b-form :class="(protospelling._editLemmaProtospelling) ? 'edit-global-hover' : ''">
                  <div class="description-group">
                    <span v-if="protospelling._editProtospelling">
                      <b-form-group
                        class="protospelling-spelling-group"
                        label="Spelling:"
                        label-for="protospelling-spelling"
                      >
                        <b-form-input
                          class="protospelling-spelling"
                          v-model="protospelling.spelling"
                          placeholder="Spelling"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="protospelling-public-comment-group"
                        label="Public comment:"
                        label-for="protospelling-public-comment"
                      >
                        <b-form-textarea
                          class="protospelling-public-comment"
                          v-model="protospelling.publicComment"
                          placeholder="Public comment"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </b-form-group>
                    </span>
                    <div v-else class="tooltip-box tooltip-box-w110">
                      <ProtospellingDisplay :protospelling="protospelling" />
                      <span class="contextmenu">
                        <button class="btn-legam-tooltip" @click="openEditProtospelling($event, protospelling)" v-if="disableEdit != true && config.editable">
                          <b-icon icon="pencil-fill"></b-icon>
                          Edit protospelling
                        </button>
                        <router-link :to="{name: 'ProtospellingDetails', params: {id: protospelling.id}}" target="_blank" class="btn-legam-tooltip">
                          <b-icon icon="search"></b-icon>
                          Details<span v-if="config.editable">/Edit</span> of protospelling
                        </router-link>
                      </span>
                    </div>
                    <hr>
                    <b-form-group
                      class="protospelling-left-context-group"
                      label="Left context"
                      label-for="protospelling-left-context"
                    >
                      <b-form-textarea
                        class="protospelling-left-context"
                        v-model="protospelling.lemmaProtospelling.leftContext"
                        placeholder="Left context"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <b-form-checkbox
                        v-model="protospelling.lemmaProtospelling.isLeftValid"
                        size="sm"
                        class="mt-1"
                        name="check-button"
                        switch
                      >
                        Valid
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      class="protospelling-right-context-group"
                      label="Right context"
                      label-for="protospelling-right-context"
                    >
                      <b-form-textarea
                        class="protospelling-right-context"
                        v-model="protospelling.lemmaProtospelling.rightContext"
                        placeholder="Right context"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <b-form-checkbox
                        v-model="protospelling.lemmaProtospelling.isRightValid"
                        size="sm"
                        class="mt-1"
                        name="check-button"
                        switch
                      >
                        Valid
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      class="protospelling-linguistic-specification-group"
                      label="Linguistic specification"
                      label-for="protospelling-linguistic-specification"
                    >
                      <b-form-textarea
                        class="protospelling-linguistic-specification"
                        v-model="protospelling.lemmaProtospelling.linguisticSpecification"
                        placeholder="Linguistic specification"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>

                    <div class="text-right">
                      <button class="btn-legam-xs mb-3" @click="closeEditProtospellingDetails($event, protospelling)">Cancel</button>
                      <button class="btn-legam-xs btn-legam-blue mb-3" @click="saveEditProtospellingDetails($event, protospelling)">Save</button>
                    </div>
                  </div>
                  <div class="tooltip-box tooltip-box-w60 protospelling-description-text mb-2">
                    <ProtospellingDisplay :protospelling="protospelling" />
                    <span class="contextmenu">
                      <button class="btn-legam-tooltip" @click="openEditLemmaProtospelling($event, protospelling)" v-if="config.editable">
                        <b-icon icon="pencil-fill"></b-icon>
                        Edit protospelling
                      </button>
                      <router-link :to="{name: 'ProtospellingDetails', params: {id: protospelling.id}}" target="_blank" class="btn-legam-tooltip">
                        <b-icon icon="search"></b-icon>
                        Protospelling Details <span v-if="config.editable">(Edit)</span>
                      </router-link>
                    </span>
                  </div>
                  <LemmaEditOccurrences
                    :occurrences="protospelling.occurrences"
                    :containerOccurrences="protospelling.containerOccurrences"
                    :references="protospelling.references"
                    :objectType="'protospelling'"
                    :objectId="protospelling.id"
                    :lemmaId="lemma.id"
                    :disableEdit="disableEdit"
                    @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
                    @removeOccurrence="removeOccurrence"
                    @removeReference="removeReference"
                    @moveToLemma="moveToLemma"
                    @moveToSenseProtospelling="moveToPS"
                    :dictionaryId="lemma.articleDictionaryId"
                  />
                  <div class="text-right mt-4">
                    <button class="btn-legam-xs" @click="closeEditBox($event, protospelling)">Close</button>
                  </div>
                </b-form>
              </div>
            </li>
          </ul>
        </span>
      </b-tab>
      <b-tab :title="this.$t('OccurrencesTableView')" @click="tabChange('table')">
        <OccurrencesTableView
          :occurrences="occurrencesAssignedToProtospellingList"
          :type="'protospelling'"
          :showComments="false"
          :allowSelection="false"
          :disableEdit="disableEdit"
          v-if="currentTab == 'table'"
          @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
        />
      </b-tab>
    </b-tabs>

    <span v-if="(currentTab == 'list' || currentTab == 'tree') && Object.keys(occurrencesWithoutProtospellingGrouped).length">
      <hr>
      <span
        @click="showWithout = !showWithout"
        class="btn-legam-xs mb-2"
        :aria-expanded="showWithout ? 'true' : 'false'"
        aria-controls="collapse-without-protopelling"
      >
        <span v-if="showWithout == false"><b-icon icon="plus"></b-icon></span>
        <span v-else><b-icon icon="x"></b-icon></span>
        {{ $t('AutomaticallyGeneratedSpellings') }}
      </span>
      <b-collapse id="collapse-without-protopelling" class="mt-3" v-model="showWithout">
        <b-card>
          <b-tabs content-class="mt-3" align="right" class="legam-tree-list-tabs no-mt">
            <b-tab title="Grouped by surface form" active @click="tabChangeWithout('group')">
              <ul class="protospelling-ul" v-if="currentTabWithout == 'group'">
                <li
                  v-for="surfaceForm in Object.keys(occurrencesWithoutProtospellingGrouped).sort(sortHelper)"
                  :key="'owps-' + surfaceForm"
                  class="tooltip-box tooltip-box-w110"
                >
                    ○ <span v-html="surfaceForm"></span>
                    <span class="occurrences">
                      <span v-for="occurrenceReference in occurrencesWithoutProtospellingGrouped[surfaceForm]" :key="occurrenceReference.id" class="occurrence">
                        <span v-if="occurrenceReference._occurrenceType == 'occurrence' || occurrenceReference._occurrenceType == 'containeroccurrence'">
                          <span v-if="occurrenceReference.suppressed">° </span>
                          <OccurrenceDisplay :occurrence="occurrenceReference" :showForm="false" type="protospelling" />
                        </span>
                        <span v-else class="reference">
                          <ReferenceDisplay :reference="occurrenceReference" :showForm="false" />
                        </span>
                      </span>
                    </span>
                    <span class="contextmenu" v-if="disableEdit != true">
                      <!-- <button
                        class="btn-legam-tooltip"
                        @click="addOccurrencegroupToProtospelling(surfaceForm)"
                        v-if="occurrencesWithoutProtospellingGrouped[surfaceForm].spelling"
                      >
                        <b-icon icon="plus-circle-fill"></b-icon>
                        Add to protospelling "{{ surfaceForm }}"
                      </button> -->
                      <button class="btn-legam-tooltip" @click="moveOcurrencesForGorup(surfaceForm)">
                        <b-icon icon="plus-circle-fill"></b-icon>
                        Move to lemma
                      </button>
                      <button class="btn-legam-tooltip" @click="addProtospellingForGorup(surfaceForm)">
                        <b-icon icon="plus-circle-fill"></b-icon>
                        Add to protospelling
                      </button>
                    </span>
                </li>
              </ul>
            </b-tab>
            <b-tab title="List" @click="tabChangeWithout('list')">
              <span v-if="currentTabWithout == 'list'">
                <span class="show-more-info">
                  <b-form-checkbox
                    id="show-details-on-occurrences-without-on-ps"
                    v-model="showDetailsOnOccurrences"
                    name="show-details-on-occurrences-without-on-ps"
                    switch
                    size="sm"
                    class="mb-3"
                  >
                    {{ $t('ShowMoreInfo') }}
                  </b-form-checkbox>
                </span>
                <b-form-checkbox-group
                  v-model="selectedOccurrences"
                  name="selected-occurrences"
                >
                  <ul class="occurrences-ul">
                    <li
                      v-for="occurrenceReference in occurrencesWithoutProtospellingList"
                      :key="'owps-list-' + occurrenceReference.id"
                      class="tooltip-box tooltip-box-w110"
                    >
                      <b-form-checkbox :value="occurrenceReference.id">
                        <span
                          v-if="occurrenceReference._occurrenceType == 'occurrence' || occurrenceReference._occurrenceType == 'containeroccurrence'"
                        >
                          <OccurrenceDisplay
                            :occurrence="occurrenceReference"
                            :showDetails="selectedOccurrences.includes(occurrenceReference.id) || showDetailsOnOccurrences"
                            type="protospelling"
                          />
                        </span>
                        <span v-else>
                          <ReferenceDisplay
                            :reference="occurrenceReference"
                            :showDetails="selectedOccurrences.includes(occurrenceReference.id) || showDetailsOnOccurrences"
                          />
                        </span>
                        <span class="contextmenu" v-if="disableEdit != true">
                          <button class="btn-legam-tooltip" @click="showOccurrenceReferenceDetails(occurrenceReference)">
                            <b-icon icon="search"></b-icon>
                            Pop-up view
                          </button>
                          <router-link
                            v-if="occurrenceReference._occurrenceType == 'occurrence' || occurrenceReference._occurrenceType == 'containeroccurrence'"
                            :to="{name: 'OccurrenceDetails', params: {id: occurrenceReference.id}}"
                            target="_blank"
                            class="btn-legam-tooltip"
                          >
                            <b-icon icon="search"></b-icon>
                            Details/Edit occurrence
                          </router-link>
                          <router-link
                            v-else
                            :to="{ name: 'ReferenceDetails', params: {id: occurrenceReference.id} }"
                            target="_blank"
                            class="btn-legam-tooltip"
                          >
                            <b-icon icon="search"></b-icon>
                            Details/Edit reference
                          </router-link>
                          <router-link
                            :to="{name: 'DocumentDetails', params: {id: occurrenceReference.token.document.id}}"
                            target="_blank"
                            class="btn-legam-tooltip"
                            v-if="occurrenceReference.token && occurrenceReference.token.document"
                          >
                            <b-icon icon="search"></b-icon>
                            Document Details
                          </router-link>
                        </span>
                      </b-form-checkbox>
                    </li>
                  </ul>
                </b-form-checkbox-group>
                <span v-if="selectedOccurrences.length && disableEdit != true">
                  <b-button v-b-modal.modal-select-protospelling size="sm">Add to protospelling</b-button>
                  <b-button v-b-modal.modal-select-lemma-ps size="sm" class="ml-1">Move to lemma</b-button>
                  <b-button variant="light" @click="selectedOccurrences = []" class="ml-1" size="sm">Deselect all</b-button>
                </span>
                <b-button variant="light" @click="selectAllOccurrences" class="ml-1" size="sm">Select all</b-button>
              </span>
            </b-tab>
            <b-tab title="Table" @click="tabChangeWithout('table')">
              <OccurrencesTableView
                :occurrences="occurrencesWithoutProtospellingList"
                :selectedOccurrences="selectedOccurrences"
                :type="'protospelling'"
                :showComments="false"
                :allowSelection="true"
                :disableEdit="disableEdit"
                @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
                @selected="selectedInTable"
                @addTo="addToProtospelling"
                v-if="currentTabWithout == 'table'"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-collapse>
    </span>

    <b-modal
      id="modal-select-protospelling"
      size="lg"
      @cancel="selectedOccurrences = []"
      @ok="selectNewProtospelling"
      title="Add occurrences or references to protospelling"
    >
      <ProtospellingAutocomplete
        :protospellingTree="protospellingsWithLevels()"
        :allowNew="true"
        @select="addOccurrencesToProtospelling"
      />
    </b-modal>

    <b-modal
      id="modal-select-lemma-ps"
      size="lg"
      @cancel="selectedOccurrences = []"
      @ok="moveOccurrencesToLemma"
      title="Move occurrences or references to lemma"
    >
      <LemmaSelect
        idClass="protospelling"
        @select="selectLemmaForOccurrenceMoveTo"
        :exclude="[this.lemma.id]"
      />
    </b-modal>

    <b-modal
      id="modal-select-ps-ps"
      size="lg"
      @cancel="selectedOccurrences = []"
      @ok="moveOccurrencesToPS"
      title="Move occurrences or references to protospelling"
    >
      <ProtospellingAutocomplete
        :protospellingTree="protospellingsWithLevels()"
        :allowNew="true"
        @select="moveOccurrencesToProtospelling"
      />
    </b-modal>

    <b-modal
      id="modal-ps-add-to-tree"
      size="lg"
      @ok="addNewProtospellingToTree"
      title="Add new protospelling to tree"
    >
      <ProtospellingAutocompleteTree :exclude="Object.keys(lemma.protospellings)" />
    </b-modal>
  </div>
</template>

<style scoped>
  .protospelling-box {
    margin-bottom: 20px;
  }

  .contextmenu {
    top: 10px;
    right: 10px;
  }

  .add-new-box {
    margin-top: -16px;
    background-color: #f1f1f1;
    margin-left: -4px;
    padding: 20px 7px 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 10px;
  }

  .protospelling-box span.tooltip-box:not(:last-child)::after {
    content: ", ";
  }

  .occurrences .occurrence:not(:last-child)::after,
  .references .reference:not(:last-child)::after {
    content: " //";
  }

  .occurrences .occurrence,
  .references .reference {
    /* padding: 2px; */
    border-radius: 4px;
    line-height: 30px;
  }

  span.not-empty:empty::before {
    content: '(!) Edit new protospelling';
    display: inline-block;
  }

  .edit-box-content .occurrences .occurrence:not(:last-child)::after {
    content: '';
  }

  .edit-box-content .occurrences .occurrence {
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
  }

  .occurrences,
  .references {
    opacity: 0.75;
  }

  ul.hierarchy-sort li[data-tree-level="2"] {
    margin-left: 20px;
  }

  ul.hierarchy-sort li[data-tree-level="3"] {
    margin-left: 40px;
  }

  ul.hierarchy-sort li[data-tree-level="4"] {
    margin-left: 60px;
  }

  ul.hierarchy-sort li[data-tree-level="5"] {
    margin-left: 80px;
  }

  .edit-box-content {
    display: none;
    background-color: #e8e8e875;
    z-index: 10;
    width: 100%;
    padding: 10px 10px;
    margin-top: 3px;
    margin-bottom: 4px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.17);
  }

  li.tooltip-box.edit-hover .edit-box-content {
    display: block;
  }

  .tooltip-box.edit-hover .li-content {
    display: none;
  }

  .btn-legam-add:hover {
    background-color: #5a6268;
    border-color: #545b62;
  }

  ul.protospelling-ul {
    list-style-type: '';
    padding-left: 0px;
  }

  ul li div.li-content {
    padding: 4px 8px 4px 0;
  }

  ul li::marker {
    color: #969696;
  }

  .description-group {
    display: none;
  }

  .edit-global-hover .description-group {
    display: block;
  }

  .edit-global-hover .protospelling-description-text {
    display: none;
  }

  .custom-control {
    position: unset !important;
  }
</style>

<script>
  import Vue from 'vue'
  import HelpStore from '@/store/helpstore.js'
  import Utils from '@/utils.js'
  import TreeView from '@/components/TreeView.vue'
  import LemmaEditOccurrences from '@/components/Lemma/EditOccurrences.vue'
  import ProtospellingAutocomplete from '@/components/ProtospellingAutocomplete.vue'
  import ProtospellingAutocompleteTree from '@/components/ProtospellingAutocompleteTree.vue'
  import OccurrenceDisplay from '@/components/Lemma/OccurrenceDisplay.vue'
  import ReferenceDisplay from '@/components/Lemma/ReferenceDisplay.vue'
  import ProtospellingDisplay from '@/components/Lemma/ProtospellingDisplay.vue'
  import OccurrencesTableView from '@/components/Lemma/OccurrencesTableView.vue'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'
  import config from '@/config.js'

  export default {
    name: 'LemmaEditProtospelling',
    props: ['lemma', 'disableEdit', 'isDocLing'],
    components: {
      TreeView,
      LemmaEditOccurrences,
      ProtospellingAutocomplete,
      OccurrenceDisplay,
      ReferenceDisplay,
      ProtospellingDisplay,
      OccurrencesTableView,
      LemmaSelect,
      ProtospellingAutocompleteTree,
    },
    data(){
      let protospellingTree = this.lemma.protospellingTree
      if (protospellingTree == null){
        protospellingTree = {
          id: null,
          children: []
        }
      }
      return {
        config: config,
        protospellings: this.lemma.protospellings,
        protospellingTree: protospellingTree,
        currentTab: 'tree',
        currentTabWithout: 'group',
        selectedOccurrences: [],
        editingProtospelling: null,
        showDetailsOnOccurrences: false,
        showWithout: this.isDocLing == true || !config.editable ? false : true,
        limit: 100,
        selectedLemma: null,
        moveForPsId: null,
      }
    },
    computed: {
      ulSortType(){
        let classes = []
        if (this.$store.state.user.settings.listViewOrder == 'flattened-hierarchy'){
          classes.push('hierarchy-sort')
        }
        return classes
      },
      combinedProtospellingsData(){
        let protospellings = []
        // Sort as needed
        if (this.$store.state.user.settings.listViewOrder == 'lexical'){
          protospellings = Object.values(this.protospellings).slice(0, this.limit)
          // protospellings = Object.values(this.protospellings)
          protospellings.sort((psA, psB) => {
            let retval = 0
            if (psA.spelling > psB.spelling){
              retval = 1
            }
            else if (psA.spelling < psB.spelling){
              retval = -1
            }
            return retval
          })
        }
        else {
          protospellings = this.protospellingsWithLevels()
        }

        // protospellings.slice(0, this.limit).forEach(protospelling => {
        protospellings.forEach(protospelling => {
          if (protospelling._prepared != true){
            // Add real occurrence data to "occurrencesSurfaceFormGrouped"
            // Object.keys(protospelling.occurrencesSurfaceFormGrouped).slice(0, this.limit).forEach(groupSurface => {
            Object.keys(protospelling.occurrencesSurfaceFormGrouped).forEach(groupSurface => {
              let _newData = []
              protospelling.occurrencesSurfaceFormGrouped[groupSurface].forEach(occurrenceData => {
              // protospelling.occurrencesSurfaceFormGrouped[groupSurface].slice(0, this.limit).forEach(occurrenceData => {
                let occurrence = null
                if (occurrenceData[1] == 'occurrence'){
                  if (occurrenceData[0] in this.lemma.occurrencesDictIndex){
                    occurrence = this.lemma.occurrences[this.lemma.occurrencesDictIndex[occurrenceData[0]]].occurrence
                    if (occurrence.tokenId != null){
                      occurrence.token = this.lemma.tokens[this.lemma.tokensDictIndex[occurrence.tokenId]]
                    }
                  }
                }
                else if (occurrenceData[1] == 'reference'){
                  occurrence = this.lemma.references[this.lemma.referencesDictIndex[occurrenceData[0]]]
                }
                else {
                  occurrence = this.lemma.occurrences[this.lemma.containerOccurrencesDictIndex[occurrenceData[0]]].containerOccurrence
                }
                if (occurrence){
                  occurrence.suppressed = occurrence.suppressedSpellingDictionaries.map(dictionary => dictionary.id).includes(this.lemma.articleDictionaryId)
                  _newData.push(occurrence)
                }
              })
              // Delete empty groups
              if (_newData.length > 0){
                protospelling.occurrencesSurfaceFormGrouped[groupSurface] = Utils.sortOccurrencesReferences(_newData, 'date')
              }
              else {
                delete protospelling.occurrencesSurfaceFormGrouped[groupSurface]
              }
            })

            // Add real occurrence data to "occurrences"
            protospelling.occurrences = protospelling.occurrences.filter(
              occurrenceId => this.lemma.occurrencesDictIndex[occurrenceId] > -1
            ).map(
              occurrenceId => this.lemma.occurrences[this.lemma.occurrencesDictIndex[occurrenceId]].occurrence
            )
            protospelling.containerOccurrences = protospelling.containerOccurrences.filter(
              containerOccurrenceId => this.lemma.containerOccurrencesDictIndex[containerOccurrenceId] > -1
            ).map(
              containerOccurrenceId => this.lemma.containerOccurrences[this.lemma.containerOccurrencesDictIndex[containerOccurrenceId]].occurrence
            )
            protospelling.references = protospelling.references.filter(
              referenceId => this.lemma.referencesDictIndex[referenceId] > -1
            ).map(
              referenceId => this.lemma.references[this.lemma.referencesDictIndex[referenceId]]
            )
            protospelling._prepared = true
          }
        })
        return protospellings
      },
      treeForProtospelling(){
        return this.makeProtospellingTree(this.protospellingTree).children
      },
      occurrencesWithoutProtospellingGrouped(){
        let retval = {}
        let connectedProtospllings = Object.keys(this.protospellings)
        for (let surfaceForm of Object.keys(this.lemma.occurrencesSurfaceFormGrouped)){
        // for (let surfaceForm of Object.keys(this.lemma.occurrencesSurfaceFormGrouped).slice(0, 10)){
          for (let occurrenceReferenceData of this.lemma.occurrencesSurfaceFormGrouped[surfaceForm].list){
          // for (let occurrenceReferenceData of this.lemma.occurrencesSurfaceFormGrouped[surfaceForm].list.slice(0, 20)){
          // for (let occurrenceReferenceData of this.lemma.occurrencesSurfaceFormGrouped[surfaceForm].list.slice(0, 20)){
            let occurrenceReferenceId = occurrenceReferenceData[0]
            let occurrenceReferenceType = occurrenceReferenceData[1]
            if (occurrenceReferenceType == 'occurrence' || occurrenceReferenceType == 'cointaineroccurrence'){
              let occurrenceIndex = null
              if (occurrenceReferenceType == 'occurrence'){
                occurrenceIndex = this.lemma.occurrencesDictIndex[occurrenceReferenceId]
              }
              else {
                occurrenceIndex = this.lemma.containerOccurrencesDictIndex[occurrenceReferenceId]
              }
              let occurrenceLemma = this.lemma.occurrences[occurrenceIndex]

              if (occurrenceLemma){
                let occurrence = occurrenceLemma[occurrenceReferenceType]
                if (occurrence.tokenId != null){
                  occurrence.token = this.lemma.tokens[this.lemma.tokensDictIndex[occurrence.tokenId]]
                }

                // We want just occurrences without protospellings
                if (!connectedProtospllings.includes(occurrence.protospellingId)){
                  if (retval[surfaceForm] == undefined){
                    retval[surfaceForm] = []
                  }
                  retval[surfaceForm].push(occurrence)
                }
              }
            }
            else {
              let occurrenceReferenceId = occurrenceReferenceData[0]
              let referenceIndex = this.lemma.referencesDictIndex[occurrenceReferenceId]
              let reference = this.lemma.references[referenceIndex]

              // We want just references without protospellings
              if (reference && reference.protospellingIds.length == 0){
                if (retval[surfaceForm] == undefined){
                  retval[surfaceForm] = []
                }
                retval[surfaceForm].push(reference)
              }
            }
          }
          if (retval[surfaceForm]){
            retval[surfaceForm] = Utils.sortOccurrencesReferences(retval[surfaceForm], 'date')
          }
          else {
            delete retval[surfaceForm]
          }
        }
        return retval
      },
      occurrencesWithoutProtospellingList(){
        let retval = []
        let protospellingIds = Object.keys(this.protospellings)
        for (let lemmaOccurrence of this.lemma.occurrences){
          let occurrence = null
          if (lemmaOccurrence.containerOccurrenceId){
            occurrence = lemmaOccurrence.containerOccurrence
          }
          else {
            occurrence = lemmaOccurrence.occurrence
          }

          if (!protospellingIds.includes(occurrence.protospellingId)){
            if (occurrence.tokenId != null){
              occurrence.token = this.lemma.tokens[this.lemma.tokensDictIndex[occurrence.tokenId]]
            }
            retval.push(occurrence)
          }
        }

        for (let reference of this.lemma.references){
          if (reference.protospellingIds.filter(protospellingId => Object.keys(this.protospellings).includes(protospellingId)).length == 0){
            retval.push(reference)
          }
        }
        return Utils.sortOccurrencesReferences(retval, 'form')
      },
      occurrencesAssignedToProtospellingList(){
        // Used in table view
        let retval = []
        let protospellingIds = Object.keys(this.protospellings)
        for (let lemmaOccurrence of this.lemma.occurrences){
          let occurrence = null
          if (lemmaOccurrence.containerOccurrenceId){
            occurrence = lemmaOccurrence.containerOccurrence
          }
          else {
            occurrence = lemmaOccurrence.occurrence
          }

          // Bug in DB
          if (protospellingIds.includes(occurrence.protospellingId)){
            if (occurrence.tokenId != null){
              occurrence.token = this.lemma.tokens[this.lemma.tokensDictIndex[occurrence.tokenId]]
            }
            retval.push(occurrence)
          }
        }

        for (let reference of this.lemma.references){
          if (reference.protospellingIds.filter(protospellingId => Object.keys(this.protospellings).includes(protospellingId)).length > 0){
            retval.push(reference)
          }
        }
        return retval
      }
    },
    watch: {
      "protospellings"(){
        this.$emit('updateProtospellings', this.protospellings, this.protospellingTree)
      },
    },
    methods: {
      sortHelper: Utils.sortHelper,
      selectedInTable(itemIds){
        this.selectedOccurrences = itemIds
      },
      selectLemmaForOccurrenceMoveTo(lemma){
        this.selectedLemma = lemma
      },
      moveToLemma(selectedOccurrences, protospellingId){
        this.$bvModal.show("modal-select-lemma-ps")
        this.selectedOccurrences = selectedOccurrences
        this.moveForPsId = protospellingId
      },
      moveToPS(selectedOccurrences, protospellingId){
        this.$bvModal.show("modal-select-ps-ps")
        this.selectedOccurrences = selectedOccurrences
        this.moveForPsId = protospellingId
      },
      moveOccurrencesToPS(){
        this._removeOccurrencesReferencesWhenMove()
        this.selectNewProtospelling()
      },
      _removeOccurrencesReferencesWhenMove(){
        this.selectedOccurrences.forEach(occurrenceReferenceId => {
          if (occurrenceReferenceId in this.lemma.occurrencesDictIndex ||
              occurrenceReferenceId in this.lemma.containerOccurrencesDictIndex
          ){
            this.removeOccurrence(occurrenceReferenceId, this.moveForPsId)
          }
          else {
            this.removeReference(occurrenceReferenceId, this.moveForPsId)
          }
        })
      },
      moveOccurrencesToProtospelling(protospelling){
        this._removeOccurrencesReferencesWhenMove()
        this.$emit('addOccurrencesReferencesToProtospelling', this.selectedOccurrences, protospelling.id)
        this.selectedOccurrences = []
        // Check if protospelling exists
        if (!(protospelling.id in this.protospellings)){
          this.protospellings[protospelling.id] = protospelling
          this.protospellingTree.children.push({id: protospelling.id, children: []})
        }
        this.$bvModal.hide('modal-select-ps-ps')
      },
      moveOccurrencesToLemma(){
        if (this.selectedLemma){
          this.$emit("removeOccurrencesReferences", this.selectedOccurrences, this.selectedLemma)
          this.$bvModal.hide("modal-select-lemma-ps")
          if (this.moveForPsId){
            this.selectedOccurrences.forEach(occurrenceId => {
              this.removeOccurrence(occurrenceId, this.moveForPsId)
            })
          }
          this.selectedLemma = null
          this.selectedOccurrences = []
        }
      },
      selectNewProtospelling(){
        let protospelling = HelpStore.item
        this.$store.dispatch('addProtospellingToLemma', {
          lemmaId: this.lemma.id,
          protospellingId: protospelling.id,
          lemmaProtospelling: protospelling.lemmaProtospelling
        }).then(() => {
          if (!('occurrences' in protospelling)){
            protospelling['occurrences'] = []
          }
          if (!('containerOccurrences' in protospelling)){
            protospelling['containerOccurrences'] = []
          }
          if (!('occurrencesSurfaceFormGrouped' in protospelling)){
            protospelling['occurrencesSurfaceFormGrouped'] = {}
          }
          if (!('references' in protospelling)){
            protospelling['references'] = []
          }
          this.protospellings[protospelling.id] = protospelling
          this.protospellingTree.children.push({id: protospelling.id, children: []})
          this.$emit('addOccurrencesReferencesToProtospelling', this.selectedOccurrences, protospelling.id)
          this.selectedOccurrences = []
        })
      },
      addToProtospelling(){
        this.$bvModal.show('modal-select-protospelling')
      },
      selectAllOccurrences(){
        this.selectedOccurrences = this.occurrencesWithoutProtospellingList.map(occurrence => occurrence.id)
      },
      getProtospellingForSurefaceForm(surfaceForm){
        let protospellingId = null
        if (this.lemma.occurrencesSurfaceFormGrouped[surfaceForm].spelling){
          protospellingId = this.lemma.occurrencesSurfaceFormGrouped[surfaceForm].spelling.id
        }
        return protospellingId
      },
      addOccurrencesToProtospelling(protospelling){
        this.$emit('addOccurrencesReferencesToProtospelling', this.selectedOccurrences, protospelling.id)
        this.selectedOccurrences = []
        // Check if protospelling exists
        if (!(protospelling.id in this.protospellings)){
          this.protospellings[protospelling.id] = protospelling
          this.protospellingTree.children.push({id: protospelling.id, children: []})
        }
        this.$bvModal.hide('modal-select-protospelling')
      },
      addProtospellingForGorup(surfaceForm){
        this.selectedOccurrences = this.occurrencesWithoutProtospellingGrouped[surfaceForm].map(
          occurrenceReference => occurrenceReference.id
        )
        this.$bvModal.show('modal-select-protospelling')
      },
      moveOcurrencesForGorup(surfaceForm){
        this.selectedOccurrences = this.occurrencesWithoutProtospellingGrouped[surfaceForm].map(
          occurrenceReference => occurrenceReference.id
        )
        this.$bvModal.show('modal-select-lemma-ps')
      },
      addOccurrencegroupToProtospelling(surfaceForm){
        this.selectedOccurrences = []
        let protospellingId = this.getProtospellingForSurefaceForm(surfaceForm)
        this.$emit(
          'addOccurrencesReferencesToProtospelling',
          this.occurrencesWithoutProtospellingGrouped[surfaceForm].map(
            occurrenceReference => occurrenceReference.id
          ),
          protospellingId
        )
      },
      protospellingsWithLevels(){
        let protospellings = []
        if (this.protospellingTree){
          this.protospellingTree.level = 0
          let treeItemsToCheck = [this.protospellingTree]
          while (treeItemsToCheck.length){
            let item = treeItemsToCheck.pop()
            treeItemsToCheck = treeItemsToCheck.concat([...item.children.map(x => {
              return {
                ...x,
                level: item.level + 1
              }
            })].reverse())

            if (item.id){
              let protospelling = this.protospellings[item.id]
              protospelling.level = item.level
              protospellings.push(protospelling)
            }
          }
        }
        return protospellings
      },
      makeProtospellingTree(jsonData){
        let languageSpec = (jsonData.id && this.protospellings[jsonData.id].lemmaProtospelling.linguisticSpecification) ? `${this.protospellings[jsonData.id].lemmaProtospelling.linguisticSpecification} ` : ''
        let prename = (jsonData.id && this.protospellings[jsonData.id].lemmaProtospelling.leftContext) ? `<i>[${this.protospellings[jsonData.id].lemmaProtospelling.leftContext}]</i> ` : ''
        let postname = (jsonData.id && this.protospellings[jsonData.id].lemmaProtospelling.rightContext) ? ` <i>[${this.protospellings[jsonData.id].lemmaProtospelling.rightContext}]</i>` : ''

        return {
          id: jsonData.id,
          name: (jsonData.id) ? languageSpec + prename + '<i>' + this.protospellings[jsonData.id].spelling + '</i>' + postname : null,
          children: jsonData.children.map(x => {
            return this.makeProtospellingTree(x)
          })
        }
      },
      showAddNewProtospelling(){
        this.$bvModal.show("modal-ps-add-to-tree");
      },
      closeAddNewProtospelling(){
        this.$bvModal.hide("modal-ps-add-to-tree");
      },
      addNewProtospellingToTree(){
        let protospelling = HelpStore.item
        this.closeAddNewProtospelling()
        if (!Object.keys(this.protospellings).includes(protospelling.id)){
          this.$store.dispatch('loadProtospelling', {
            protospellingId: protospelling.id,
            lemmaId: this.lemma.id
          }).then((_protospelling) => {
            _protospelling.lemmaProtospelling = protospelling.lemmaProtospelling

            this.$store.dispatch('addProtospellingToLemma', {
              lemmaId: this.lemma.id,
              protospellingId: protospelling.id,
              lemmaProtospelling: protospelling.lemmaProtospelling,
            }).then(() => {
              this.protospellings[protospelling.id] = _protospelling
              this.protospellingTree.children.push({id: protospelling.id, children: []})
              this.$store.dispatch('addNotification', {
                type: 'success',
                text: `New protospelling added`
              })
              this.$forceUpdate()
            })
          })
        }
      },
      removeOccurrence(occurrenceId, protospellingId){
        let occurrence = this.protospellings[protospellingId].occurrences.filter(
          occurrence => occurrence.id == occurrenceId
        )[0]
        this.protospellings[protospellingId].occurrences = this.protospellings[protospellingId].occurrences.filter(
          _occurrence => _occurrence.id != occurrenceId
        )
        if (occurrence.externalEvidence){
          this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[occurrence.externalEvidence.surfaceForm] = this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[occurrence.externalEvidence.surfaceForm].filter(
            (x) => x[0] != occurrenceId
          )

          if (this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[occurrence.externalEvidence.surfaceForm].length == 0){
            delete this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[occurrence.externalEvidence.surfaceForm]
          }
        }
        occurrence.protospellingId = null
        this.$forceUpdate();
      },
      removeReference(referenceId, protospellingId){
        let reference = this.protospellings[protospellingId].references.filter(
          reference => reference.id == referenceId
        )[0]
        this.protospellings[protospellingId].references = this.protospellings[protospellingId].references.filter(
          _reference => _reference.id != referenceId
        )
        if (reference.surfaceForm){
          this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[reference.surfaceForm] = this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[reference.surfaceForm].filter(
            (x) => x[0] != referenceId
          )

          if (this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[reference.surfaceForm].length == 0){
            delete this.protospellings[protospellingId].occurrencesSurfaceFormGrouped[reference.surfaceForm]
          }
        }
        reference.protospellingIds = reference.protospellingIds.filter(_protospellingId => _protospellingId != protospellingId)
        this.$forceUpdate();
      },
      showOccurrenceReferenceDetails(occurrence_reference){
        this.$emit('showOccurrenceReferenceDetails', occurrence_reference)
      },
      openEditLemmaProtospelling(event, protospelling){
        event.preventDefault()
        Vue.set(protospelling, '_editLemmaProtospelling', true)
        Vue.set(protospelling, '_editProtospelling', false)
        this.$forceUpdate()
      },
      openEditProtospelling(event, protospelling){
        event.preventDefault()
        this.$bvModal.msgBoxOk('Editing protospelling will affect all connected lemmas!');
        Vue.set(protospelling, '_editProtospelling', true)
        this.$forceUpdate()
      },
      closeEditProtospellingDetails(event, protospelling){
        event.preventDefault()
        Vue.set(protospelling, '_editProtospelling', false)
        Vue.set(protospelling, '_editLemmaProtospelling', false)
        this.$forceUpdate()
      },
      saveEditProtospellingDetails(event, protospelling){
        event.preventDefault()
        this.$store.dispatch('saveProtospellingSense', {
          lemmaId: this.lemma.id,
          objectType: 'protospelling',
          protospelling: protospelling
        }).then(() => {
          Vue.set(protospelling, '_editProtospelling', false)
          Vue.set(protospelling, '_editLemmaProtospelling', false)
          this.$forceUpdate()
        })
      },
      showProtospellingDetails(event, protospelling){
        event.preventDefault()
        this.closeAllBoxes()
        Vue.set(protospelling, '_details', true)
        this.$forceUpdate()
      },
      deleteProtospelling(protospellingId){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              delete this.protospellings[protospellingId]
              this.protospellingTree = {
                id: null,
                children: this._deleteNode(this.protospellingTree.children, protospellingId)
              }
              this.$store.dispatch('saveLemmaOccurrenceTree', {
                tree: this.protospellingTree,
                objectType: 'protospelling',
                lemmaId: this.lemma.id
              }).then(() => {
                this.$store.dispatch('addNotification', {
                  type: 'success',
                  text: `Protospelling deleted`
                })
                this.$forceUpdate()
              })
            }
          })
      },
      _deleteNode(children, protospellingIdToDelete){
        // TODO: move to utils
        let ret = []
        for(let protospelling of children){
          if (protospelling.id != protospellingIdToDelete){
            protospelling.children = this._deleteNode(protospelling.children, protospellingIdToDelete)
            ret.push(protospelling)
          }
        }
        return ret
      },
      closeAllBoxes(){
        Object.values(this.protospellings).forEach(protospelling => {
          Vue.set(protospelling, '_details', false)
          Vue.set(protospelling, '_editProtospelling', false)
          Vue.set(protospelling, '_editLemmaProtospelling', false)
        })
        this.$forceUpdate()
        return false
      },
      closeEditBox(event, protospelling){
        event.preventDefault()
        Vue.set(protospelling, '_details', false)
        this.$forceUpdate()
        return false
      },
      saveEditOccurrenceBox(event, protospellingId){
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Protospelling "${this.protospellings[protospellingId].spelling}" saved`
        })
        this.closeEditBox(event, protospellingId)
      },
      saveTree(newTree){
        this.$bvModal.hide("modal-ps-add-to-tree");
        this.$store.dispatch('saveLemmaOccurrenceTree', {
          tree: {
            children: newTree,
            id: null
          },
          objectType: 'protospelling',
          lemmaId: this.lemma.id
        }).then(() => {
          this.protospellingTree = {
            children: newTree,
            id: null
          }
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Protospelling tree saved`
          })
          this.$forceUpdate()
        })
      },
      tabChange(tab){
        this.currentTab = tab
      },
      tabChangeWithout(tab){
        this.currentTabWithout = tab
      }
    }
  }
</script>
