<template>
  <div class="senses">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <b-form-group
            class="sense-group"
            label="Sense"
            label-for="sense"
          >
            <SenseSelect
              @select="select"
              @selectNew="selectNew"
              :sense="sense"
              :key="indexSense"
              :exclude="exclude"
            />
          </b-form-group>
          <b-modal id="modal-new-sense" title="New sense" ok-title="Save" @ok="saveSense" size="lg">
            <SenseNewEdit :sense="sense" />
          </b-modal>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-form-group
            class="sense-left-context-group"
            label="Left context"
            label-for="sense-left-context"
          >
            <b-form-textarea
              class="sense-left-context"
              v-model="sense.lemmaSense.leftContext"
              placeholder="Left context"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-checkbox
              v-model="sense.lemmaSense.isLeftValid"
              size="sm"
              class="mt-1"
              name="check-button"
              switch
            >
              Valid
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            class="sense-right-context-group"
            label="Right context"
            label-for="sense-right-context"
          >
            <b-form-textarea
              class="sense-right-context"
              v-model="sense.lemmaSense.rightContext"
              placeholder="Right context"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-checkbox
              v-model="sense.lemmaSense.isRightValid"
              size="sm"
              class="mt-1"
              name="check-button"
              switch
            >
              Valid
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
</style>

<script>
  import SenseNewEdit from '@/components/SenseNewEdit.vue'
  import SenseSelect from '@/components/Dictionary/SenseSelect.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'SenseAutocomplete',
    props: ['exclude'],
    data() {
      return {
        indexSense: 0,
        sense: {
          id: null,
          definition: '',
          categories: [],
          lemmaSense: {
            leftContext: '',
            isLeftValid: false,
            rightContext: '',
            isRightValid: false,
          },
        }
      }
    },
    components: {
      SenseNewEdit,
      SenseSelect,
    },
    updated(){
      HelpStore.item = this.sense
    },
    methods: {
      select(sense){
        sense.lemmaSense = this.sense.lemmaSense
        this.sense = sense
        HelpStore.item = this.sense
      },
      selectNew(definition){
        this.sense.definition = definition
        this.$bvModal.show('modal-new-sense')
      },
      saveSense(){
        this.$store.dispatch('createSense', HelpStore.item).then((response) => {
          let sense = response.data
          sense.lemmaSense = this.sense.lemmaSense
          this.sense = sense
          this.indexSense++
          HelpStore.item = this.sense
        })
      }
    }
  }
</script>
