<template>
  <div class="references">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'ReferenceSelect',
    props: ['exclude', 'reference', 'forLemmaId'],
    components: {
      Select2
    },
    mounted() {
      if (this.reference){
        this.$refs.select2.select2.append(
          `<option selected value="${this.reference.id}">${this.reference.display}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          ajax: {
            delay: 250,
            url: config.apiUrl + '/references/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term,
                forLemmaId: this.forLemmaId
              }
            },
            processResults: (data) => {
              return {
                results: data.map(reference => {
                  return {
                    id: reference.id,
                    text: reference.display,
                    reference: reference
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.reference)
      }
    }
  }
</script>
