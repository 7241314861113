<template>
  <div class="etymology">
    <h6 class="mt-4">
      <!-- Etymology -->
      <span class="btn-legam-xs btn-legam-xxs ml-2" @click="addNew" v-if="disableEdit != true && Object.values(models).length == 0">
        <b-icon icon="pencil-fill"></b-icon>
        Add
      </span>
    </h6>
    <div v-for="etymology in models" :key="etymology.id">
      <div v-if="etymology.isEdit" class="occurrence-form edit-box-content">
        <b-form-group
          id="etymology-group"
        >
          <b-form-textarea
            id="etymology-context"
            v-model="etymology.description"
            placeholder="Etymology"
            rows="6"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <div class="float-left">
          <b-dropdown id="dropdown-1" text="Load template" class="m-md-2" size="sm">
            <b-dropdown-item v-for="item in $store.state.common.etymologySkeletons" :key="item.id" @click="loadTemplate(etymology.id, item)">
              {{ item.acronym }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="float-right">
          <div class="text-right" style="clear: both">
            <button class="btn-legam-xs btn-legam-red" @click="deleteEtymology(etymology)">Delete</button>
            <button class="btn-legam-xs" @click="closeEdit(etymology)">Close</button>
            <button class="btn-legam-xs btn-legam-blue" @click="save(etymology)">Save</button>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <div v-else class="occurrence-text">
        <p class="mb-2">
          <span class="btn-legam-xs btn-legam-xxs ml-2 float-right" @click="openEdit(etymology)" v-if="disableEdit != true">
            <b-icon icon="pencil-fill"></b-icon>
            Edit
          </span>
          <ExpandElement
            :dataJson="expandEtymologyData(etymology)"
            :defaultVisible="true"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .edit-box-content {
    background-color: #e8e8e875;
    z-index: 10;
    width: 100%;
    padding: 10px 10px;
    margin-top: 3px;
    margin-bottom: 4px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.17);
  }
  /* .occurrence {
    margin-bottom: 5px;
  }

  .occurrence-text {
    padding: 2px 5px;
    cursor: pointer;
    border-radius: 3px;
  }

  span.not-empty:empty::before {
    content: 'Edit new occurrence';
    display: inline-block;
  }

  .occurrence-form {
    margin-left: 5px;
  }

  .occurrence:hover > .occurrence-text {
    background-color: #d6d6d6;
  } */
</style>

<script>
  import ExpandElement from '@/components/ExpandElement.vue'

  export default {
    name: 'LemmaEditEtymology',
    props: ['etymologies', 'lemmaId', 'disableEdit'],
    components: {
      ExpandElement
    },
    data(){
      let models = {}
      this.etymologies.forEach(etymology => {
        models[etymology.id] = {
          id: etymology.id,
          description: etymology.description,
          lemmaId: etymology.lemmaId,
          isEdit: false,
          saved: true
        }
      })
      return {
        models: models,
        indexNew: 0
      }
    },
    methods: {
      loadTemplate(etymologyId, item){
        this.models[etymologyId].description = item.content
        this.$forceUpdate()
      },
      prepareEtymologie(text) {
        let footnoteIndex = 1
        let footnotes = []
        let index = text.indexOf("<footnote>")
        while (index > -1) {
          footnotes.push([text.substring(index+10, text.indexOf("</footnote>")), footnoteIndex])
          text = text.substring(0, index) + `<sup>${footnoteIndex}°</sup> ` + text.substring(text.indexOf("</footnote>") + 11)
          index = text.indexOf("<footnote>")
          footnoteIndex++
        }
        if (footnotes.length) {
          text = text + `<br>`
        }
        for (const [footText, index] of footnotes) {
          text = text + `<sup>${index}°</sup> ${footText}<br>`
        }
        return text
      },
      expandEtymologyData(etymology){
        return {
          title: '[⁠Étymologie⁠]',
          text: etymology.description ? this.prepareEtymologie(etymology.description) : '',
          isSafe: true,
          to: null
        }
      },
      openEdit(etymology){
        etymology.isEdit = true
        this.$forceUpdate()
      },
      closeEdit(etymology){
        etymology.isEdit = false
        if (etymology.saved == false){
          delete this.models[etymology.id]
        }
        this.$forceUpdate()
      },
      deleteEtymology(etymology){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteEtymology', {
              lemmaId: this.lemmaId,
              etymology: etymology
            }).then(() => {
              delete this.models[etymology.id]
              this.$forceUpdate()
              this.$emit('update', this.models)
            })
          }
        })
      },
      save(etymology){
        this.$store.dispatch('saveEtymology', {
          lemmaId: this.lemmaId,
          etymology: etymology
        }).then((response) => {
          etymology.saved = true
          etymology.isEdit = false
          delete this.models[etymology.id]
          etymology = response.data
          etymology.saved = true
          etymology.isEdit = false
          this.models[etymology.id] = etymology
          this.$forceUpdate()
          this.$emit('update', this.models)
        })
      },
      addNew(){
        let newId = '_new' + this.indexNew
        this.models[newId] = {
          id: newId,
          // lemmaId: 0,
          description: '',
          isEdit: true,
          saved: false
        }
        this.indexNew++
        this.$forceUpdate()
      }
    }
  }
</script>
