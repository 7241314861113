<template>
  <div class="senses-box">
    <!-- <h6 class="mt-4 legam-h6">
      Sense
    </h6> -->
    <b-tabs content-class="mt-3 ml-2" class="legam-tree-list-tabs no-mt">
      <b-tab title="this.$t('Tree')" active @click="tabChange('tree')">
        <span v-if="currentTab == 'tree'">
          <TreeView
            :data="treeForSense"
            :bullet="'◆'"
            @save="saveTree"
            @add="showAddNewSense"
            v-if="senseTree && Object.values(senseTree.children).length"
            :disableEdit="!$store.getters.checkRole('legam.dict.lemma.treerearrange')"
            :disableAdd="!$store.getters.checkRole('legam.dict.lemma.treerearrange')"
          >
            <template v-slot:leafNameDisplay="slotProps">
              <span v-html="slotProps.model.name"></span>
              <span class="contextmenu" v-if="$store.getters.checkRole('legam.dict.lemma.treerearrange')">
                <button class="btn-legam-tooltip" @click="deleteSense(slotProps.model.id)">
                  <b-icon icon="trash-fill"></b-icon>
                  Remove
                </button>
              </span>
            </template>
          </TreeView>
          <span v-else-if="config.editable">
            <span class="btn-legam-xs btn-legam-xxs ml-2" @click="showAddNewSense">
              <b-icon icon="plus"></b-icon>
              Add
            </span>
          </span>
        </span>
      </b-tab>
      <b-tab title="this.$t('List')" @click="tabChange('list')">
        <span v-if="currentTab == 'list'">
          <ul class="sense-ul" v-if="senseTree && Object.values(senseTree.children).length">
            <li
              v-for="sense in sortedSenses()"
              :key="sense.id"
              class="tooltip-box tooltip-box-w110"
              :class="(sense._details) ? 'edit-hover' : ''"
              :data-tree-level="sense.level"
            >
              <div class="li-content">
                <SenseDisplay :sense="sense" />&nbsp;
                <div class="occurrences">
                  <span class="occurrence" v-for="(occurrence, index) in sortedOccurrences(sense)" :key="occurrence.id">
                    <span v-if="isSuppressed(occurrence)">°</span>
                    <OccurrenceDisplay
                      :occurrence="occurrence"
                      :showForm="index == 0 || sortedOccurrences(sense)[index - 1].surfaceForm != occurrence.surfaceForm"
                      type="sense"
                    />
                  </span>
                </div>
                <span v-if="sense.references.length">
                  <hr class="mt-1 mb-1">
                  <span class="references">
                    <span class="reference" v-for="(reference, index) in sortedReferences(sense)" :key="reference.id">
                      &nbsp;
                      <span v-if="isSuppressed(reference)">°</span>
                      <ReferenceDisplay
                        :reference="reference"
                        :showForm="index == 0 || sortedReferences(sense)[index - 1].surfaceForm != reference.surfaceForm"
                      />
                    </span>
                  </span>
                </span>
                <span class="contextmenu">
                  <button class="btn-legam-tooltip" @click="showSenseDetails($event, sense)">
                    <b-icon icon="eye"></b-icon>
                    Show details
                  </button>
                  <router-link :to="{name: 'Sense', params: {id: sense.id}}" target="_blank" class="btn-legam-tooltip" v-if="config.editable">
                    <b-icon icon="search"></b-icon>
                    Sense Details <span v-if="config.editable">(Edit)</span>
                  </router-link>
                </span>
              </div>
              <div class="edit-box-content">
                <b-form :class="(sense._editLemmaSense) ? 'edit-global-hover' : ''">
                  <div class="definition-group">
                    <span v-if="sense._editSense">
                      <b-form-group
                        label="Definition"
                        label-for="definition"
                      >
                        <b-form-textarea
                          class="definition"
                          v-model="sense.definition"
                          placeholder="Definition"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        label="Paradefinition"
                        label-for="paradefinition"
                      >
                        <b-form-textarea
                          class="paradefinition"
                          v-model="sense.paradefinition"
                          placeholder="Pardefinition"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        class=""
                        label="Designation"
                        label-for="designation"
                      >
                        <b-form-textarea
                          class="designation"
                          v-model="sense.designation"
                          placeholder="Designation"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </b-form-group>
                    </span>
                    <div v-else class="tooltip-box tooltip-box-w110">
                      <SenseDisplay :sense="sense" />
                      <span class="contextmenu" v-if="disableEdit != true && config.editable">
                        <button class="btn-legam-tooltip" @click="openEditSense($event, sense)">
                          <b-icon icon="pencil-fill"></b-icon>
                          Edit sense
                        </button>
                        <router-link :to="{name: 'Sense', params: {id: sense.id}}" target="_blank" class="btn-legam-tooltip">
                          <b-icon icon="search"></b-icon>
                          Sense Details <span v-if="config.editable">(Edit)</span>
                        </router-link>
                      </span>
                    </div>
                    <hr>
                    <b-form-group
                      class="sense-left-context-group"
                      label="Left context"
                      label-for="sense-left-context"
                    >
                      <b-form-textarea
                        class="sense-left-context"
                        v-model="sense.lemmaSense.leftContext"
                        placeholder="Left context"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <b-form-checkbox
                        v-model="sense.lemmaSense.isLeftValid"
                        size="sm"
                        class="mt-1"
                        name="check-button"
                        switch
                      >
                        Valid
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      class="sense-right-context-group"
                      label="Right context"
                      label-for="sense-right-context"
                    >
                      <b-form-textarea
                        class="sense-right-context"
                        v-model="sense.lemmaSense.rightContext"
                        placeholder="Right context"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <b-form-checkbox
                        v-model="sense.lemmaSense.isRightValid"
                        size="sm"
                        class="mt-1"
                        name="check-button"
                        switch
                      >
                        Valid
                      </b-form-checkbox>
                    </b-form-group>
                    <div class="text-right">
                      <button class="btn-legam-xs mb-3" @click="closeEditLemmaSense($event, sense)">Cancel</button>
                      <button class="btn-legam-xs btn-legam-blue mb-3" @click="saveEditSenseLemma($event, sense)">Save</button>
                    </div>
                  </div>
                  <div class="tooltip-box tooltip-box-w60 sense-definition-text mb-2">
                    <SenseDisplay :sense="sense" />
                    <span class="contextmenu">
                      <button class="btn-legam-tooltip" @click="openEditLemmaSense($event, sense)" v-if="config.editable">
                        <b-icon icon="pencil-fill"></b-icon>
                        Edit sense
                      </button>
                      <router-link :to="{name: 'Sense', params: {id: sense.id}}" target="_blank" class="btn-legam-tooltip">
                        <b-icon icon="search"></b-icon>
                        Details<span v-if="config.editable">/Edit</span>
                      </router-link>
                    </span>
                  </div>
                  <LemmaEditOccurrences
                    :occurrences="sense.occurrences"
                    :containerOccurrences="sense.containerOccurrences"
                    :references="sense.references"
                    :objectType="'sense'"
                    :objectId="sense.id"
                    :lemmaId="lemma.id"
                    :disableEdit="disableEdit"
                    @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
                    @removeOccurrence="removeOccurrence"
                    @removeReference="removeReference"
                    @moveToLemma="moveToLemma"
                    @moveToSenseProtospelling="moveToSense"
                    :dictionaryId="lemma.articleDictionaryId"
                  />
                  <div class="text-right mt-4">
                    <button class="btn-legam-xs" @click="closeEditBox($event, sense)">Close</button>
                  </div>
                </b-form>
              </div>
            </li>
          </ul>
        </span>
      </b-tab>
      <b-tab title="this.$t('DetailedList')" @click="tabChange('detailed-list')">
        <span v-if="currentTab == 'detailed-list'">
          <b-form-group
            id="selected-senese"
            label="Show occurrences for sense:"
          >
            <Select2
              :options="senseOptions"
              v-model="selectedOccurrencesSenseId"
              :settings="{multiple: false, width: '100%'}"
              class="mb-4"
            />
          </b-form-group>
          <OccurrencesTableView
            :occurrences="occurrencesAssignedToSenseList"
            :type="'sense'"
            :showComments="true"
            :allowSelection="false"
            :key="occurrenceTableKey"
            :disableEdit="disableEdit"
            @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
          />
        </span>
      </b-tab>
    </b-tabs>

    <span v-if="(currentTab == 'tree' || currentTab == 'list') && Object.keys(occurrencesWithoutSense).length && config.editable">
      <hr>
      <span v-b-toggle.collapse-without-sense @click="showWithout = !showWithout" class="btn-legam-xs mb-2">
        <span v-if="showWithout == false"><b-icon icon="plus"></b-icon></span>
        <span v-else><b-icon icon="x"></b-icon></span>
        {{ $t('OccurrencesAndReferencesWithoutSense') }}
      </span>
      <b-collapse id="collapse-without-sense" class="mt-2">
        <b-card>
          <b-tabs content-class="mt-3" align="right" class="legam-tree-list-tabs no-mt">
            <b-tab title="List" active @click="tabChangeWithout('list')">
              <span v-if="currentTabWithout == 'list'">
                <span class="show-more-info">
                  <b-form-checkbox
                    id="show-details-on-occurrences-without-on-senses"
                    v-model="showDetailsOnOccurrences"
                    name="show-details-on-occurrences-without-on-senses"
                    switch
                    size="sm"
                    class="mb-3"
                  >
                    Show more info
                  </b-form-checkbox>
                </span>
                <b-form-checkbox-group
                  v-model="selectedOccurrences"
                  name="selected-occurrences"
                >
                  <ul class="occurrences-ul">
                    <li
                      v-for="occurrenceReference in occurrencesWithoutSense"
                      :key="'ows-' + occurrenceReference.id"
                      class="tooltip-box tooltip-box-w110"
                    >
                      <b-form-checkbox :value="occurrenceReference.id">
                        <span
                          v-if="occurrenceReference._occurrenceType == 'occurrence' || occurrenceReference._occurrenceType == 'containeroccurrence'"
                        >
                          <OccurrenceDisplay
                            :occurrence="occurrenceReference"
                            :showDetails="selectedOccurrences.includes(occurrenceReference.id) || showDetailsOnOccurrences"
                            type="sense"
                          />
                        </span>
                        <span v-else>
                          <ReferenceDisplay
                            :reference="occurrenceReference"
                            :showDetails="selectedOccurrences.includes(occurrenceReference.id) || showDetailsOnOccurrences"
                          />
                        </span>
                        <span class="contextmenu" v-if="disableEdit != true">
                          <button class="btn-legam-tooltip" @click="showOccurrenceReferenceDetails(occurrenceReference)">
                            <b-icon icon="search"></b-icon>
                            Pop-up view
                          </button>
                          <router-link
                            :to="{name: 'OccurrenceDetails', params: {id: occurrenceReference.id}}"
                            target="_blank"
                            class="btn-legam-tooltip"
                            v-if="occurrenceReference._occurrenceType == 'occurrence' || occurrenceReference._occurrenceType == 'containeroccurrence'"
                          >
                            <b-icon icon="search"></b-icon>
                            Details<span v-if="config.editable">/Edit</span>
                          </router-link>
                          <router-link v-else :to="{ name: 'ReferenceDetails', params: {id: occurrenceReference.id} }" target="_blank" class="btn-legam-tooltip">
                            <b-icon icon="search"></b-icon>
                            Details<span v-if="config.editable">/Edit</span>
                          </router-link>
                          <router-link
                            :to="{name: 'DocumentDetails', params: {id: occurrenceReference.token.document.id}}"
                            target="_blank"
                            class="btn-legam-tooltip"
                            v-if="occurrenceReference.token && occurrenceReference.token.document"
                          >
                            <b-icon icon="search"></b-icon>
                            Document Details
                          </router-link>
                        </span>
                      </b-form-checkbox>
                    </li>
                  </ul>
                </b-form-checkbox-group>
                <span v-if="selectedOccurrences.length && disableEdit != true && config.editable">
                  <b-button v-b-modal.modal-select-sense size="sm">Add to sense</b-button>
                  <b-button v-b-modal.modal-select-lemma-sense size="sm" class="ml-1">Move to lemma</b-button>
                  <b-button variant="light" @click="selectedOccurrences = []" size="sm" class="ml-2 mr-2">Deselect all</b-button>
                </span>
                <b-button variant="light" @click="selectAllOccurrences" class="ml-1" size="sm">Select all</b-button>
              </span>
            </b-tab>
            <b-tab title="Table" @click="tabChangeWithout('table')">
              <OccurrencesTableView
                :occurrences="occurrencesWithoutSense"
                :selectedOccurrences="selectedOccurrences"
                :type="'sense'"
                :showComments="true"
                :allowSelection="true"
                :disableEdit="disableEdit"
                @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
                @selected="selectedInTable"
                @addTo="addToSense"
                v-if="currentTabWithout == 'table'"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-collapse>
    </span>

    <b-modal
      id="modal-select-sense"
      size="lg"
      @cancel="selectedOccurrences = []"
      @ok="selectNewSense"
      title="Add occurrences or references to sense"
    >
      <SenseAutocomplete
        :allowNew="true"
        :senseTree="sortedSenses()"
        @select="addOccurrencesToSense"
      />
    </b-modal>

    <b-modal
      id="modal-select-sense-sense"
      size="lg"
      @cancel="selectedOccurrences = []"
      @ok="moveOccurrencesToSenseOk"
      title="Move occurrences or references to sense"
    >
      <SenseAutocomplete
        :allowNew="true"
        :senseTree="sortedSenses()"
        @select="moveOccurrencesToSense"
      />
    </b-modal>

    <b-modal
      id="modal-select-lemma-sense"
      size="lg"
      @cancel="selectedOccurrences = []"
      @ok="moveOccurrencesToLemma"
      title="Move occurrences or references to lemma"
    >
      <LemmaSelect
        idClass="sense"
        @select="selectLemmaForOccurrenceMoveTo"
        :exclude="[this.lemma.id]"
      />
    </b-modal>

    <b-modal
      id="modal-sense-add-to-tree"
      size="lg"
      @ok="addNewSenseToTree"
      title="Add new sense to tree"
    >
      <SenseAutocompleteTree :exclude="Object.keys(lemma.senses)" />
    </b-modal>
  </div>
</template>

<style scoped>
  .references .reference:not(:last-child)::after {
    content: " //";
  }

  .contextmenu {
    top: 10px;
    right: 10px;
  }

  .add-new-box {
    margin-top: -16px;
    background-color: #f1f1f1;
    margin-left: -4px;
    padding: 20px 7px 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 10px;
  }

  .edit-box-content .occurrences .occurrence:not(:last-child)::after,
  .edit-box-content .references .reference:not(:last-child)::after {
    content: '';
  }

  .edit-box-content .occurrences .occurrence {
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
  }

  span.not-empty:empty::before {
    content: '(!) Edit new sense';
    display: inline-block;
  }

  .edit-box-content {
    display: none;
    background-color: #e8e8e875;
    z-index: 10;
    width: 100%;
    padding: 10px 10px;
    margin-top: 3px;
    margin-bottom: 4px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.17);
  }

  li.tooltip-box.edit-hover .edit-box-content {
    display: block;
  }

  .tooltip-box.edit-hover .li-content {
    display: none;
  }

  li[data-tree-level="2"] {
    margin-left: 20px;
  }

  li[data-tree-level="3"] {
    margin-left: 40px;
  }

  li[data-tree-level="4"] {
    margin-left: 60px;
  }

  li[data-tree-level="5"] {
    margin-left: 80px;
  }

  ul.sense-ul {
    list-style-type: '◆ ';
    padding-left: 10px;
  }

  ul li div.li-content {
    padding: 4px 8px;
  }

  ul li::marker {
    color: #969696;
  }

  .definition-group {
    display: none;
  }

  .edit-global-hover .definition-group {
    display: block;
  }

  .edit-global-hover .sense-definition-text {
    display: none;
  }

  * >>> .sense-ontology {
    float: right;
  }

  .occurrences,
  .references {
    opacity: 0.75;
  }

  .occurrences .occurrence,
  .references .reference {
    /* padding: 2px; */
    border-radius: 4px;
    line-height: 30px;
  }

  .occurrences .occurrence {
    display: block;
  }

  .show-more-info > div {
    margin-top: -7px;
  }
  * >>> .show-more-info .custom-control-label {
    text-transform: none;
  }
  .custom-control {
    position: unset !important;
  }
</style>

<script>
  import Vue from 'vue'
  import Utils from '@/utils.js'
  import HelpStore from '@/store/helpstore.js'
  import TreeView from '@/components/TreeView.vue'
  // import LemmaEditOccurrences from '@/components/Lemma/EditOccurrences.vue'
  import SenseAutocomplete from '@/components/SenseAutocomplete.vue'
  // import SenseDisplay from '@/components/Lemma/SenseDisplay.vue'
  import SenseAutocompleteTree from '@/components/SenseAutocompleteTree.vue'
  import OccurrenceDisplay from '@/components/Lemma/OccurrenceDisplay.vue'
  import ReferenceDisplay from '@/components/Lemma/ReferenceDisplay.vue'
  import OccurrencesTableView from '@/components/Lemma/OccurrencesTableView.vue'
  import Select2 from 'v-select2-component'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'
  import config from '@/config.js'

  export default {
    name: 'LemmaEditSense',
    props: ['lemma', 'disableEdit'],
    components: {
      TreeView,
      // LemmaEditOccurrences,
      SenseAutocomplete,
      // SenseDisplay,
      OccurrenceDisplay,
      ReferenceDisplay,
      OccurrencesTableView,
      Select2,
      LemmaSelect,
      SenseAutocompleteTree,
    },
    data(){
      return {
        config: config,
        senses: this.lemma.senses,
        senseTree: this.lemma.senseTree,
        currentTab: 'tree',
        currentTabWithout: 'list',
        selectedOccurrences: [],
        showDetailsOnOccurrences: false,
        showWithout: false,
        selectedOccurrencesSenseId: -1,
        occurrenceTableKey: 0,
        selectedLemma: null,
        moveForSenseId: null,
      }
    },
    watch: {
      selectedOccurrencesSenseId() {
        this.occurrenceTableKey++
      }
    },
    computed: {
      treeForSense(){
        return this.makeSenseTree(this.senseTree).children
      },
      occurrencesWithoutSense(){
        let retval = []

        let senseIds = Object.keys(this.senses)
        for (let lemmaOccurrence of this.lemma.occurrences){
          let occurrence = null
          if (lemmaOccurrence.containerOccurrenceId){
            occurrence = lemmaOccurrence.containerOccurrence
          }
          else {
            occurrence = lemmaOccurrence.occurrence
          }

          if (!senseIds.includes(occurrence.senseId)){
            if (occurrence.tokenId != null){
              occurrence.token = this.lemma.tokens[this.lemma.tokensDictIndex[occurrence.tokenId]]
            }
            retval.push(occurrence)
          }
        }

        for (let reference of this.lemma.references){
          if (reference.senseIds.filter(senseId => Object.keys(this.senses).includes(senseId)).length == 0){
            retval.push(reference)
          }
        }
        return Utils.sortOccurrencesReferences(retval, 'form')
      },
      occurrencesAssignedToSenseList(){
        let retval = []
        let occurrencesInSense = this.selectedOccurrencesSenseId != -1 ? [this.selectedOccurrencesSenseId] : Object.keys(this.senses)

        for (let lemmaOccurrence of this.lemma.occurrences){
          let occurrence = null
          if (lemmaOccurrence.containerOccurrenceId){
            occurrence = lemmaOccurrence.containerOccurrence
          }
          else {
            occurrence = lemmaOccurrence.occurrence
          }

          if (occurrencesInSense.includes(occurrence.senseId)){
            if (occurrence.tokenId != null){
              occurrence.token = this.lemma.tokens[this.lemma.tokensDictIndex[occurrence.tokenId]]
            }
            retval.push(occurrence)
          }
        }

        for (let reference of this.lemma.references){
          if (reference.senseIds.filter(senseId => occurrencesInSense.includes(senseId)).length > 0){
            retval.push(reference)
          }
        }
        return retval
      },
      senseOptions(){
        let senses = [{
            id: -1,
            text: 'All senses',
            sense: null
          }]
        senses = senses.concat(this.sortedSenses().map(sense => {
          return {
            id: sense.id,
            text: sense.display,
            sense: sense
          }
        }))
        return senses.sort((a, b) => (a.text > b.text) ? 1 : -1)
      },
    },
    methods: {
      sortedOccurrences(sense){
        if (sense._prepared != true){
          let _newOccurrenceData = []
          sense.occurrences.forEach(occurrenceId => {
            if (occurrenceId in this.lemma.occurrencesDictIndex){
              let occurrenceIndex = this.lemma.occurrencesDictIndex[occurrenceId]
              _newOccurrenceData.push(this.lemma.occurrences[occurrenceIndex].occurrence)
            }
            else if (occurrenceId in this.lemma.containerOccurrencesDictIndex){
              let occurrenceIndex = this.lemma.containerOccurrencesDictIndex[occurrenceId]
              _newOccurrenceData.push(this.lemma.occurrences[occurrenceIndex].containerOccurrences)
            }
          })
          sense.occurrences = _newOccurrenceData

          let _newReferenceData = []
          sense.references.forEach(referenceId => {
            if (referenceId in this.lemma.referencesDictIndex){
              let referenceIndex = this.lemma.referencesDictIndex[referenceId]
              _newReferenceData.push(this.lemma.references[referenceIndex])
            }
          })
          sense.references = _newReferenceData
          sense._prepared = true
        }
        let occurrences = sense.occurrences.concat(sense.containerOccurrences)
        return Utils.sortOccurrencesReferences(occurrences, 'date')
      },
      selectLemmaForOccurrenceMoveTo(lemma){
        this.selectedLemma = lemma
      },
      moveToLemma(selectedOccurrences, senseId){
        this.$bvModal.show("modal-select-lemma-sense")
        this.selectedOccurrences = selectedOccurrences
        this.moveForSenseId = senseId
      },
      moveToSense(selectedOccurrences, senseId){
        this.$bvModal.show("modal-select-sense-sense")
        this.selectedOccurrences = selectedOccurrences
        this.moveForSenseId = senseId
      },
      moveOccurrencesToSenseOk(){
        this._removeOccurrencesReferencesWhenMove()
        this.selectNewSense()
      },
      _removeOccurrencesReferencesWhenMove(){
        this.selectedOccurrences.forEach(occurrenceReferenceId => {
          if (occurrenceReferenceId in this.lemma.occurrencesDictIndex ||
              occurrenceReferenceId in this.lemma.containerOccurrencesDictIndex
          ){
            this.removeOccurrence(occurrenceReferenceId, this.moveForSenseId)
          }
          else {
            this.removeReference(occurrenceReferenceId, this.moveForSenseId)
          }
        })
      },
      moveOccurrencesToSense(sense){
        this._removeOccurrencesReferencesWhenMove()
        this.$emit('addOccurrencesReferencesToSense', this.selectedOccurrences, sense)
        this.selectedOccurrences = []
        // Check if sense exists
        if (!(sense.id in this.senses)){
          this.senses[sense.id] = sense
        }
        this.$bvModal.hide('modal-select-sense-sense')
      },
      moveOccurrencesToLemma(){
        if (this.selectedLemma){
          this.$emit("removeOccurrencesReferences", this.selectedOccurrences, this.selectedLemma)
          this.$bvModal.hide("modal-select-lemma-sense")
          if (this.moveForSenseId){
            this.selectedOccurrences.forEach(occurrenceId => {
              this.removeOccurrence(occurrenceId, this.moveForSenseId)
            })
          }
          this.selectedLemma = null
          this.selectedOccurrences = []
        }
      },
      selectNewSense(){
        let sense = HelpStore.item
        this.$store.dispatch('addSenseToLemma', {
          lemmaId: this.lemma.id,
          senseId: sense.id,
          lemmaSense: sense.lemmaSense
        }).then(() => {
          this.$emit('addOccurrencesReferencesToSense', this.selectedOccurrences, sense)
          this.selectedOccurrences = []
        })
      },
      sortedReferences(sense){
        // Trick to make new array because of loop
        let references = sense.references.concat([])
        return Utils.sortOccurrencesReferences(references)
      },
      selectedInTable(itemIds){
        this.selectedOccurrences = itemIds
      },
      addToSense(){
        this.$bvModal.show('modal-select-sense')
      },
      addOccurrencesToSense(sense){
        this.$emit('addOccurrencesReferencesToSense', this.selectedOccurrences, sense)
        this.selectedOccurrences = []
        // Check if sense exists
        if (!(sense.id in this.senses)){
          this.senses[sense.id] = sense
        }
        this.$bvModal.hide('modal-select-sense')
      },
      selectAllOccurrences(){
        this.selectedOccurrences = this.occurrencesWithoutSense.map(occurrence => occurrence.id)
      },
      sortedSenses(){
        let senses = Object.values(this.senses)
        if (this.senseTree){
          let rootSensesIds = Object.values(this.senseTree.children).map(senseTreeItem => senseTreeItem.id)
          for(let sense of senses){
            if (rootSensesIds.includes(sense.id)){
              sense.displayNumber = rootSensesIds.indexOf(sense.id) + 1 + '<sup>o</sup> '
            }
          }
        }

        // Issue #297
        // if (this.$store.state.user.settings.listViewOrder == 'lexical'){
        //   senses.sort((senseA, senseB) => {
        //     let retval = 0
        //     if (senseA.display > senseB.display){
        //       retval = 1
        //     }
        //     else if (senseA.display < senseB.display){
        //       retval = -1
        //     }
        //     return retval
        //   })
        // }
        // else {
        senses = []
        if (this.senseTree){
          this.senseTree.level = 0
          let treeItemsToCheck = [this.senseTree]
          while (treeItemsToCheck.length){
            let item = treeItemsToCheck.pop()
            treeItemsToCheck = treeItemsToCheck.concat([...item.children.map(x => {
              return {
                ...x,
                level: item.level + 1
              }
            })].reverse())
            if (item.id){
              let sense = this.senses[item.id]
              sense.level = item.level
              senses.push(sense)
            }
          }
        }
        // }
        return senses
      },
      makeSenseTree(jsonData){
        let rootSensesIds = Object.values(this.senseTree.children).map(senseTreeItem => senseTreeItem.id)
        let prename = rootSensesIds.includes(jsonData.id) ? rootSensesIds.indexOf(jsonData.id) + 1 + '<sup>o</sup>' : ''
        if (jsonData.id && this.senses[jsonData.id].lemmaSense.leftContext){
          prename = `${prename} <i>[${this.senses[jsonData.id].lemmaSense.leftContext}]</i>`
        }
        prename = prename + ' '
        let postname = (jsonData.id && this.senses[jsonData.id].lemmaSense.rightContext) ? ` <i>[${this.senses[jsonData.id].lemmaSense.rightContext}]</i>` : ''
        return {
          id: jsonData.id,
          name: prename + (jsonData.id ? this.senses[jsonData.id].display : null) + postname,
          children: jsonData.children.map(x => {
            return this.makeSenseTree(x)
          })
        }
      },
      showAddNewSense(){
        this.$bvModal.show("modal-sense-add-to-tree");
      },
      closeAddNewsense(){
        this.$bvModal.hide("modal-sense-add-to-tree");
      },
      isSuppressed(occurrence){
        return occurrence.suppressedSenseDictionaries.map(dictionary => dictionary.id).includes(this.lemma.articleDictionaryId)
      },
      addNewSenseToTree(){
        let sense = HelpStore.item
        this.closeAddNewsense()

        if (!Object.keys(this.senses).includes(sense.id)){
          this.$store.dispatch('loadSense', {
            senseId: sense.id,
            lemmaId: this.lemma.id
          }).then((_sense) => {
            _sense.lemmaSense = sense.lemmaSense

            this.$store.dispatch('addSenseToLemma', {
              lemmaId: this.lemma.id,
              senseId: sense.id,
              lemmaSense: sense.lemmaSense
            }).then(() => {
              this.senses[sense.id] = _sense
              if (this.senseTree === null){
                this.senseTree = {
                  id: null,
                  children: []
                }
              }
              this.senseTree.children.push({id: sense.id, children: []})
              this.$store.dispatch('addNotification', {
                type: 'success',
                text: `New sense added`
              })
              this.$forceUpdate()
            })
          })
        }
      },
      removeOccurrence(occurrenceId, senseId){
        let occurrence = this.senses[senseId].occurrences.filter(
          occurrence => occurrence.id == occurrenceId
        )[0]
        if (occurrence){
          occurrence.senseId = null
          this.senses[senseId].occurrences = this.senses[senseId].occurrences.filter(
            occurrence => occurrence.id != occurrenceId
          )
          this.$forceUpdate()
        }
      },
      removeReference(referenceId, senseId){
        let reference = this.senses[senseId].references.filter(
          reference => reference.id == referenceId
        )[0]
        reference.senseIds = reference.senseIds.filter(_senseId => _senseId != senseId)
        this.senses[senseId].references = this.senses[senseId].references.filter(
          reference => reference.id != referenceId
        )
        this.$forceUpdate()
      },
      showSenseDetails(event, sense){
        event.preventDefault()
        this.closeAllBoxes()
        Vue.set(this.senses[sense.id], '_details', true)
        this.$forceUpdate()
      },
      openEditLemmaSense(event, sense){
        event.preventDefault()
        Vue.set(sense, '_editSense', false)
        Vue.set(sense, '_editLemmaSense', true)
        this.$forceUpdate()
      },
      closeEditLemmaSense(event, sense){
        event.preventDefault()
        Vue.set(sense, '_editSense', false)
        Vue.set(sense, '_editLemmaSense', false)
        this.$forceUpdate()
      },
      openEditSense(event, sense){
        event.preventDefault()
        this.$bvModal.msgBoxOk('Editing sense will affect all connected lemmas!');
        Vue.set(sense, '_editSense', true)
        this.$forceUpdate()
      },
      saveEditSenseLemma(event, sense){
        event.preventDefault()
        this.$store.dispatch('saveProtospellingSense', {
          lemmaId: this.lemma.id,
          objectType: 'sense',
          objectId: sense.id,
          sense: sense
        }).then(() => {
          sense.display = sense.definition || sense.paradefinition || sense.designation
          Vue.set(sense, '_editSense', false)
          Vue.set(sense, '_editLemmaSense', false)
          this.$forceUpdate()
        })
      },
      deleteSense(senseId){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              delete this.senses[senseId]
              this.senseTree = {
                id: null,
                children: this._deleteSenseNodes(this.senseTree.children, senseId)
              }
              this.$store.dispatch('saveLemmaOccurrenceTree', {
                tree: this.senseTree,
                objectType: 'sense',
                lemmaId: this.lemma.id
              }).then(() => {
                this.$store.dispatch('addNotification', {
                  type: 'success',
                  text: `Sense deleted`
                })
                this.$forceUpdate()
              })
            }
          })
      },
      showOccurrenceReferenceDetails(occurrence_reference){
        this.$emit('showOccurrenceReferenceDetails', occurrence_reference)
      },
      closeAllBoxes(){
        Object.keys(this.senses).forEach(senseId => {
          Vue.set(this.senses[senseId], '_details', false)
          Vue.set(this.senses[senseId], '_editSense', false)
          Vue.set(this.senses[senseId], '_editLemmaSense', false)
        })
        this.$forceUpdate()
        return false
      },
      _deleteSenseNodes(children, senseIdToDelete){
        // TODO: move to utils
        let ret = []
        for(let sense of children){
          if (sense.id != senseIdToDelete){
            sense.children = this._deleteSenseNodes(sense.children, senseIdToDelete)
            ret.push(sense)
          }
        }
        return ret
      },
      closeEditBox(event, sense){
        event.preventDefault()
        Vue.set(sense, '_details', false)
        this.$forceUpdate()
        return false
      },
      saveTree(newTree){
        this.$bvModal.hide("modal-sense-add-to-tree");
        this.$store.dispatch('saveLemmaOccurrenceTree', {
          tree: {
            children: newTree,
            id: null
          },
          objectType: 'sense',
          lemmaId: this.lemma.id
        }).then(() => {
          this.senseTree = {
            children: newTree,
            id: null
          }
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Sense tree saved`
          })
          this.$forceUpdate()
        })
      },
      tabChange(tab){
        this.currentTab = tab
      },
      tabChangeWithout(tab){
        this.currentTabWithout = tab
      }
    }
  }
</script>
