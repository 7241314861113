<template>
  <div>
    <!-- {{ occurrence_reference }} -->

    <b-container fluid="xxl" v-if="occurrence_reference._occurrenceType && occurrence_reference.externalEvidence">
      <b-row>
        <b-col>
          Type: <b>{{occurrence_reference._occurrenceType}} (external)</b><br><br>

          Surface form: <span v-html="occurrence_reference.externalEvidence.surfaceForm"></span><br>
          Text Date: <span v-html="occurrence_reference.externalEvidence.calcDateSpecText"></span><br>
          Manuscript date: <span v-html="occurrence_reference.externalEvidence.calcDateSpecManuscript"></span><br>
          Text Location: <span v-html="occurrence_reference.externalEvidence.calcTextLocation"></span><br>
          Manuscript Location: <span v-html="occurrence_reference.externalEvidence.calcManuscriptLocation"></span><br>
          Edition sigle: <span v-if="occurrence_reference.externalEvidence.edition" v-html="occurrence_reference.externalEvidence.edition.sigleLegam"></span><br>
          Position: <span v-html="occurrence_reference.externalEvidence.position"></span><br>
          Scriptorium: <span v-if="occurrence_reference.externalEvidence.manuscript" v-html="occurrence_reference.externalEvidence.manuscript.codeRepcrit"></span><br>
        </b-col>
        <b-col>
          <p class="text-bold">Comment</p>
          <span v-html="occurrence_reference.comment"></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="text-bold">Context</p>
          <span v-html="occurrence_reference.externalEvidence.context"></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="text-bold">Sense comment</p>
          <span v-html="occurrence_reference.publicCommentSense"></span>
        </b-col>
        <b-col>
          <p class="text-bold">Spelling comment</p>
          <span v-html="occurrence_reference.publicCommentSpelling"></span>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid="xxl" v-else-if="occurrence_reference._occurrenceType && occurrence_reference.token">
      <b-row>
        <b-col>
          Type: <b>{{occurrence_reference._occurrenceType}} (internal)</b><br><br>

          Form: <span v-html="occurrence_reference.token.form"></span><br>
          Date: <span
            v-if="occurrence_reference.token.document && occurrence_reference.token.document.manuscript && occurrence_reference.token.document.manuscript.dateSpecPrec"
          ><span v-html="occurrence_reference.token.document.manuscript.dateSpecPrec"></span></span><br>
          Localization: <span
            v-if="occurrence_reference.token.document && occurrence_reference.token.document.manuscript && occurrence_reference.token.document.manuscript.localization"
          ><span v-html="occurrence_reference.token.document.manuscript.localization.display"></span></span><br>
          Edition sigle: <span v-if="occurrence_reference.token.document.edition" v-html="occurrence_reference.token.document.edition.sigleLegam"></span><br>
          Token length: <span v-html="occurrence_reference.token.length"></span>
        </b-col>
        <b-col>
          <p class="text-bold">Comment</p>
          <span v-html="occurrence_reference.comment"></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="text-bold">Context</p>
          <span
            v-if="occurrence_reference.token.document"
            v-html="occurrence_reference.token.document.content"
          ></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="text-bold">Sense comment</p>
          <span v-html="occurrence_reference.publicCommentSense"></span>
        </b-col>
        <b-col>
          <p class="text-bold">Spelling comment</p>
          <span v-html="occurrence_reference.publicCommentSpelling"></span>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid="xxl" v-else>
      <b-row>
        <b-col>
          Type: <b>reference</b><br><br>
          Reference: <span v-html="occurrence_reference.display"></span><br>
          Position: <span v-html="occurrence_reference.position"></span><br>
        </b-col>
        <b-col>
          <p class="text-bold">Comment</p>
          <span v-html="occurrence_reference.comment"></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="text-bold">Context</p>
          <span
            v-if="occurrence_reference.context"
            v-html="occurrence_reference.context"
          ></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="text-bold">Sense comment</p>
          <span v-html="occurrence_reference.publicCommentSense"></span>
        </b-col>
        <b-col>
          <p class="text-bold">Spelling comment</p>
          <span v-html="occurrence_reference.publicCommentSpelling"></span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .highlight {
    /* background-color: greenyellow; */
    background-color: #1e9988;
    color: #fff;
  }
</style>

<script>
  export default {
    name: 'OccurrenceReferenceDetailsModal',
    props: ['occurrence_reference'],
  }
</script>
