<template>
  <div class="occurrence">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'OccurrenceSelect',
    props: ['exclude', 'occurrence', 'forLemmaId', 'objectType'],
    components: {
      Select2
    },
    mounted() {
      if (this.occurrence){
        this.$refs.select2.select2.append(
          `<option selected value="${this.occurrence.id}">${this.occurrence.display}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          ajax: {
            delay: 250,
            url: config.apiUrl + '/occurrences/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term,
                forLemmaId: this.forLemmaId,
                objectType: this.objectType
              }
            },
            processResults: (data) => {
              return {
                results: data.map(occurrence => {
                  return {
                    id: occurrence.id,
                    text: occurrence.display,
                    occurrence: occurrence
                  }
                })
              }
            }
          },
          width: '100%'
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.occurrence)
      }
    }
  }
</script>
