<template>
  <div class="protospellings">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <b-form-group
            class="protospelling-group"
            label="Protospelling"
            label-for="protospelling"
          >
            <ProtospellingSelect
              @select="select"
              @selectNew="selectNew"
              :protospelling="protospelling"
              :key="indexProtospelling"
              :exclude="exclude"
            />
          </b-form-group>
          <b-modal id="modal-new-protospelling" title="New protospelling" ok-title="Save" @ok="saveProtospelling" size="lg">
            <ProtospellingNew :protospelling="protospelling" />
          </b-modal>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-form-group
            class="protospelling-left-context-group"
            label="Left context"
            label-for="protospelling-left-context"
          >
            <b-form-textarea
              class="protospelling-left-context"
              v-model="protospelling.lemmaProtospelling.leftContext"
              placeholder="Left context"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-checkbox
              v-model="protospelling.lemmaProtospelling.isLeftValid"
              size="sm"
              class="mt-1"
              name="check-button"
              switch
            >
              Valid
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            class="protospelling-right-context-group"
            label="Right context"
            label-for="protospelling-right-context"
          >
            <b-form-textarea
              class="protospelling-right-context"
              v-model="protospelling.lemmaProtospelling.rightContext"
              placeholder="Right context"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-checkbox
              v-model="protospelling.lemmaProtospelling.isRightValid"
              size="sm"
              class="mt-1"
              name="check-button"
              switch
            >
              Valid
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            class="protospelling-linguistic-specification-group"
            label="Linguistic specification"
            label-for="protospelling-linguistic-specification"
          >
            <b-form-textarea
              class="protospelling-linguistic-specification"
              v-model="protospelling.lemmaProtospelling.linguisticSpecification"
              placeholder="Linguistic specification"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
</style>

<script>
  import ProtospellingNew from '@/components/ProtospellingNew.vue'
  import ProtospellingSelect from '@/components/Dictionary/ProtospellingSelect.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'ProtospellingAutocomplete',
    props: ['exclude'],
    data() {
      return {
        indexProtospelling: 0,
        protospelling: {
          id: null,
          spelling: '',
          lemmaProtospelling: {
            leftContext: '',
            isLeftValid: false,
            rightContext: '',
            isRightValid: false,
            linguisticSpecification: '',
          },
        }
      }
    },
    components: {
      ProtospellingNew,
      ProtospellingSelect,
    },
    updated(){
      HelpStore.item = this.protospelling
    },
    methods: {
      select(protospelling){
        protospelling.lemmaProtospelling = this.protospelling.lemmaProtospelling
        this.protospelling = protospelling
        HelpStore.item = this.protospelling
      },
      selectNew(definition){
        this.protospelling.definition = definition
        this.$bvModal.show('modal-new-protospelling')
      },
      saveProtospelling(){
        this.$store.dispatch('createProtospelling', HelpStore.item).then((response) => {
          let protospelling = response.data
          protospelling.lemmaProtospelling = this.protospelling.lemmaProtospelling
          this.protospelling = protospelling
          this.indexProtospelling++
          HelpStore.item = this.protospelling
        })
      }
    }
  }
</script>
