<template>
  <div class="senses">
    <b-tabs content-class="mt-3 ml-2" class="legam-tree-list-tabs no-mt">
      <b-tab title="Tree" active>
        <ul class="sense-ul" v-if="senseTree">
            <li
              v-for="sense in senseTree"
              :key="'tree' + sense.id + sense.fullCode"
              class="tooltip-box tooltip-box-w110"
              :class="(sense._details) ? 'edit-hover' : ''"
              :data-tree-level="sense.level"
            >
              <div class="li-content">
                <span @click="selectSense($event, sense)" >
                  <SenseDisplay :sense="sense" />&nbsp;
                </span>
              </div>
            </li>
        </ul>
      </b-tab>
      <b-tab title="Search">
        <b-container fluid="xxl">
          <b-row>
            <b-col>
              <!-- <span href="#" v-b-modal.modal-new-sense style="float: right; color: blue">Create new sense</span> -->
              <b-form-group
                class="sense-group"
                label="Sense"
                label-for="sense"
              >
                <SenseSelect @select="select" @selectNew="selectNew" :sense="sense" :key="indexSense" />
              </b-form-group>
              <b-modal id="modal-new-sense" title="New sense" ok-title="Save" @ok="saveSense" size="lg">
                <SenseNewEdit :sense="sense" />
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-form-group
                class="sense-left-context-group"
                label="Left context"
                label-for="sense-left-context"
              >
                <b-form-textarea
                  class="sense-left-context"
                  v-model="sense.lemmaSense.leftContext"
                  placeholder="Left context"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-form-checkbox
                  v-model="sense.lemmaSense.isLeftValid"
                  size="sm"
                  class="mt-1"
                  name="check-button"
                  switch
                >
                  Valid
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                class="sense-right-context-group"
                label="Right context"
                label-for="sense-right-context"
              >
                <b-form-textarea
                  class="sense-right-context"
                  v-model="sense.lemmaSense.rightContext"
                  placeholder="Right context"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-form-checkbox
                  v-model="sense.lemmaSense.isRightValid"
                  size="sm"
                  class="mt-1"
                  name="check-button"
                  switch
                >
                  Valid
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style scoped>
  .results-ul {
    list-style-type: '';
    padding-left: 10px;
  }
  li[data-tree-level="2"] {
    margin-left: 10px;
  }

  li[data-tree-level="3"] {
    margin-left: 20px;
  }

  li[data-tree-level="4"] {
    margin-left: 30px;
  }

  li[data-tree-level="5"] {
    margin-left: 40px;
  }

  ul.sense-ul {
    list-style-type: '';
    padding-left: 10px;
  }

  ul li div.li-content {
    padding: 4px 8px;
    cursor: pointer;
  }
</style>

<script>
  import SenseNewEdit from '@/components/SenseNewEdit.vue'
  import SenseSelect from '@/components/Dictionary/SenseSelect.vue'
  import HelpStore from '@/store/helpstore.js'
  import SenseDisplay from '@/components/Lemma/SenseDisplay.vue'

  export default {
    name: 'SenseAutocomplete',
    props: ['occurrence', 'allowNew', 'exclude', 'senseTree'],
    data() {
      return {
        indexSense: 0,
        sense: {
          id: null,
          definition: '',
          categories: [],
          lemmaSense: {
            leftContext: '',
            isLeftValid: false,
            rightContext: '',
            isRightValid: false,
          },
        }
      }
    },
    components: {
      SenseNewEdit,
      SenseDisplay,
      SenseSelect,
    },
    updated(){
      HelpStore.item = this.sense
    },
    methods: {
      selectSense(event, sense){
        event.preventDefault()
        this.$emit('select', sense, this.occurrence)
      },
      select(sense){
        this.sense.id = sense.id
        this.sense.definition = sense.definition
        HelpStore.item = this.sense
      },
      selectNew(definition){
        this.sense.definition = definition
        this.$bvModal.show('modal-new-sense')
      },
      saveSense(){
        this.$store.dispatch('createSense', HelpStore.item).then((response) => {
          this.sense.id = response.data.id
          this.sense.definition = response.data.definition
          this.indexSense++
          HelpStore.item = this.sense
        })
      }
    }
  }
</script>
