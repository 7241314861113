<template>
  <span>
    <span v-if="occurrence.externalEvidence">
      <span v-if="type == 'sense' && occurrence.linguisticSpecification"><span
          v-html="occurrence.linguisticSpecification"
          title="Linguistic Specification"
          class="text-linguistic-specification"
        ></span></span>
      <span v-if="((showDetails && type == 'sense') || type != 'sense') && occurrence.externalEvidence.surfaceForm && showForm">
        <span
          v-html="occurrence.externalEvidence.surfaceForm"
          title="Surface form"
        ></span>
      </span>
      &nbsp;
      <span v-if="occurrence.externalEvidence.calcDateSpecText"
        class="text-bold" title="Text date" v-html="occurrence.externalEvidence.calcDateSpecText"
      ></span>
      <span v-else-if="occurrence.externalEvidence.calcDateNumText"
        class="text-bold" title="Text date" v-html="occurrence.externalEvidence.calcDateNumText"
      ></span>
      <span v-if="occurrence.externalEvidence.calcDateSpecManuscript"> [<span
          title="Manuscript date" v-html="occurrence.externalEvidence.calcDateSpecManuscript"
        ></span>]</span>
      <span v-else-if="occurrence.externalEvidence.calcDateNumManuscript"> [<span
          title="Manuscript date" v-html="occurrence.externalEvidence.calcDateNumManuscript"
        ></span>]</span>
      <span v-if="occurrence.externalEvidence.calcTextLocation">&nbsp;<span
          class="text-bold" title="Text location" v-html="occurrence.externalEvidence.calcTextLocation"
        ></span></span>
      <span v-if="occurrence.externalEvidence.calcManuscriptLocation"> [<span
          title="Manuscript location" v-html="occurrence.externalEvidence.calcManuscriptLocation"
        ></span>]</span>
      <span v-if="occurrence.externalEvidence.edition">&nbsp;<router-link
        v-html="occurrence.externalEvidence.edition.sigleLegam"
        :to="{name: 'EditionDetails', params: {slug: '-', id: occurrence.externalEvidence.edition.id}}"
        title="Edition Sigle"
        :style="surfaceClass"
        target="_blank"
      ></router-link></span>
      <span v-if="occurrence.externalEvidence.position">&nbsp;<span
          v-html="occurrence.externalEvidence.position"
          title="Position"
        ></span></span>
      <span v-if="(showDetails || type == 'sense') && occurrence.externalEvidence && occurrence.externalEvidence.context"> [<span
          v-html="occurrence.externalEvidence.context"
          title="Context"
        ></span>]</span>
      <span v-if="showDetails && occurrence.comment"> [<span
          v-html="occurrence.comment"
          title="Comment"
        ></span>]</span>
      <span v-if="showDetails && type == 'sense' && occurrence.publicCommentSense"> (<span
          v-html="occurrence.publicCommentSense"
          title="Public Comment"
        ></span>)</span>
      <span v-if="showDetails && type == 'protospelling' && occurrence.publicCommentSpelling"> (<span
          v-html="occurrence.publicCommentSpelling"
          title="Public Comment"
        ></span>)</span>
    </span>
    <span v-else-if="occurrence.token">
      <span v-html="occurrence.token.form" v-if="showForm"></span>
      <span v-if="occurrence.token.document && occurrence.token.document.calcDateNumText">&nbsp;<span
          class="text-italic"
          title="Text date"
          v-html="occurrence.token.document.calcDateNumText"
        ></span></span>
      <span v-if="occurrence.token.document && occurrence.token.document.calcDateNumManuscript"> [<span
          title="Manuscript date"
          v-html="occurrence.token.document.calcDateNumManuscript"
        ></span>]</span>
      <span
        v-if="occurrence.token.document && occurrence.token.document.calcTextLocation"
      >&nbsp;<span
            v-html="occurrence.token.document.calcTextLocation"
            title="Localization"
            class="text-bold"
          ></span></span>
      <span
        v-if="occurrence.token.document && occurrence.token.document.calcManuscriptLocation"
      > [<span
            v-html="occurrence.token.document.calcManuscriptLocation"
            title="Localization"
          ></span>]</span>
      <span v-if="occurrence.token && occurrence.token.document.calcShortRedactor"> (<span title="Short form of redactor" v-html="occurrence.token.document.calcShortRedactor"></span>)</span>
      <span
        v-if="occurrence.token.document && occurrence.token.document.edition"
      >&nbsp;<router-link
        v-html="occurrence.token.document.edition.sigleLegam"
        :to="{name: 'DocumentDetails', params: {id: occurrence.token.document.id}}"
        title="Edition Sigle"
        class="text-orange"
        target="_blank"
      ></router-link></span>
      &nbsp;<span title="Division" v-html="occurrence.token.division"></span>
      <span v-if="(showDetails || type == 'sense') && occurrence.token && occurrence.token.document"> [<span v-html="occurrence.token.document.content" title="Token"></span>]</span>
      <span v-if="showDetails && occurrence.comment"> [<span
        v-html="occurrence.comment"
        title="Comment"
      ></span>]</span>
      <span v-if="showDetails && type == 'sense' && occurrence.publicCommentSense"> (<span
        v-html="occurrence.publicCommentSense"
        title="Public Comment"
      ></span>)</span>
      <span v-if="showDetails && type == 'protospelling' && occurrence.publicCommentSpelling"> (<span
        v-html="occurrence.publicCommentSpelling"
        title="Public Comment"
      ></span>)</span>
    </span>
    <span v-else>-</span>
  </span>
</template>

<style scoped>
  * >>> .highlight {
    /* background-color: greenyellow; */
    background-color: #1e9988;
    color: #fff;
  }
  .text-linguistic-specification {
    color: #1e9988;
  }
</style>

<script>
  export default {
    name: 'OccurrenceDisplay',
    props: {
      occurrence: {},
      showDetails: {},
      showForm: {
        type: Boolean,
        default: true
      },
      type: null,
    },
    methods: {
      htmlSubstring(s, n) {
        var m, r = /<([^>\s]*)[^>]*>/g,
          stack = [],
          lasti = 0,
          result = '';

        //for each tag, while we don't have enough characters
        while ((m = r.exec(s)) && n) {
          //get the text substring between the last tag and this one
          var temp = s.substring(lasti, m.index).substr(0, n)
          //append to the result and count the number of characters added
          result += temp
          n -= temp.length
          lasti = r.lastIndex

          if (n) {
            result += m[0];
            if (m[1].indexOf('/') === 0) {
              //if this is a closing tag, than pop the stack (does not account for bad html)
              stack.pop();
            } else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
              //if this is not a self closing tag than push it in the stack
              stack.push(m[1]);
            }
          }
        }

        //add the remainder of the string, if needed (there are no more tags in here)
        result += s.substr(lasti, n);

        //fix the unclosed tags
        while (stack.length) {
          result += '</' + stack.pop() + '>';
        }

        return result
      }
    },
    computed: {
      surfaceClass(){
        let color = '#000'
        if (this.occurrence && this.occurrence.externalEvidence && this.occurrence.externalEvidence.study && this.occurrence.externalEvidence.study.feColor){
          color = this.occurrence.externalEvidence.study.feColor
        }
        return `color: ${color}`
      },
      tokenContent: function(){
        let content = ''
        let token = this.occurrence.token
        if (token.document){
          content = token.document.content
          let before = content.substring(0, token.idx)
          let after = content.substring(token.idx + token.length)
          content = `...${this.htmlSubstring(before.substring(before.length - 200), 200)}<span class="highlight">${content.substring(token.idx, token.idx + token.length)}</span>${this.htmlSubstring(after.substring(0, 200), 200)}...`
        }
        return content
      }
    }
  }
</script>
