<template>
  <span>
    <span v-if="showForm">
      <span class="text-blue" v-html="reference.surfaceForm"></span>
    </span>
    &nbsp;
    <span v-html="reference.siglum"></span>
    <span v-if="reference.position">&nbsp;<span v-html="reference.position"></span></span>
    <span v-if="reference.publicComment">&nbsp;<span v-html="reference.publicComment"></span></span>
    <span v-if="reference.url">&nbsp;<a :href="reference.url" target="_blank"><b-icon icon="link45deg" target="_blank"></b-icon></a></span>
    <span v-if="showDetails && reference.context"> [<span v-html="reference.context"></span>]</span>
    <span v-if="showDetails && reference.comment"> [<span v-html="reference.comment"></span>]</span>
  </span>
</template>

<script>
  export default {
    name: 'ReferenceDisplay',
    props: {reference: {}, showDetails: {}, showForm: {
      type: Boolean,
      default: true
    }}
  }
</script>
