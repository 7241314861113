<template>
  <div>
    <div class="occurrences">
      <h6 class="legam-h6 with-line">
        Occurrences
        <span class="show-more-info">
          <b-form-checkbox
            :id="'show-details-on-occurrences-' + objectType + objectId"
            v-model="showDetailsOnOccurrences"
            name="show-details-on-occurrences"
            switch
            size="sm"
            class="float-right"
          >
            Show more info
          </b-form-checkbox>
        </span>
      </h6>

      <b-form-checkbox-group
        v-model="selectedOccurrences"
        name="selected-occurrences"
      >
        <ul class="occurrences occurrences-ul">
          <li v-for="occurrence in sortedOccurrences" :key="occurrence.id">
            <b-form-checkbox :value="occurrence._occurrenceType + '|' + occurrence.id" v-if="config.editable">
            <!-- <b-form-checkbox :value="occurrence.id" class="custom-checkbox"> -->
              <LemmaEditOccurrence
                v-if="occurrence._occurrenceType == 'occurrence'"
                :occurrence="occurrence"
                :type="objectType"
                :lemmaId="lemmaId"
                :showDetails="showDetailsOnOccurrences"
                :disableEdit="disableEdit"
                @remove="removeOccurrence"
                @change="changeOccurrence"
                @showDetails="showOccurrenceReferenceDetails"
                :dictionaryId="dictionaryId"
              />
              <LemmaEditOccurrence
                v-else
                :occurrence="occurrence"
                :type="objectType"
                :lemmaId="lemmaId"
                :showDetails="showDetailsOnOccurrences"
                :disableEdit="disableEdit"
                @remove="removeContainerOccurrence"
                @change="changeContainerOccurrence"
                @showDetails="showOccurrenceReferenceDetails"
              />
            </b-form-checkbox>
            <template v-else>
              <LemmaEditOccurrence
                v-if="occurrence._occurrenceType == 'occurrence'"
                :occurrence="occurrence"
                :type="objectType"
                :lemmaId="lemmaId"
                :showDetails="showDetailsOnOccurrences"
                :disableEdit="disableEdit"
                @remove="removeOccurrence"
                @change="changeOccurrence"
                @showDetails="showOccurrenceReferenceDetails"
                :dictionaryId="dictionaryId"
              />
              <LemmaEditOccurrence
                v-else
                :occurrence="occurrence"
                :type="objectType"
                :lemmaId="lemmaId"
                :showDetails="showDetailsOnOccurrences"
                :disableEdit="disableEdit"
                @remove="removeContainerOccurrence"
                @change="changeContainerOccurrence"
                @showDetails="showOccurrenceReferenceDetails"
              />
            </template>
          </li>
        </ul>
        <div v-if="selectedOccurrences.length && disableEdit != true" class="mt-2">
          <b-button @click="moveToLemma(selectedOccurrences, objectId)" size="sm">Move to lemma</b-button>
          <b-button @click="moveToSenseProtospelling(selectedOccurrences, objectId)" size="sm" class="ml-1">Move to {{ objectType }}</b-button>
          <b-button variant="light" @click="removeSelected" class="ml-1" size="sm">Remove all selected occurrences</b-button>
        </div>
      </b-form-checkbox-group>
    </div>
    <div class="references mt-5">
      <h6 class="legam-h6 with-line">
        References
        <span class="show-more-info">
          <b-form-checkbox
            :id="'show-details-on-references-' + objectType + objectId"
            v-model="showDetailsOnReferences"
            name="show-details-on-references"
            switch
            size="sm"
            class="float-right"
          >
            Show more info
          </b-form-checkbox>
        </span>
      </h6>
      <ul class="occurrences occurrences-ul">
        <li v-for="reference in x_references" :key="reference.id">
          <LemmaEditReference
            :reference="reference"
            :type="objectType"
            :lemmaId="lemmaId"
            :showDetails="showDetailsOnReferences"
            :disableEdit="disableEdit"
            @remove="removeReference"
            @showDetails="showOccurrenceReferenceDetails"
            @change="changeReference"
            :dictionaryId="dictionaryId"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
  div.occurrences {
    margin-bottom: -26px;
  }
  .show-more-info > div {
    margin-top: -7px;
  }
  * >>> .show-more-info .custom-control-label {
    text-transform: none;
  }
  .custom-control {
    position: unset !important;
  }
</style>>

<script>
  import Utils from '@/utils.js'
  import LemmaEditOccurrence from '@/components/Lemma/EditOccurrence.vue'
  import LemmaEditReference from '@/components/Lemma/EditReference.vue'
  import config from '@/config.js'

  export default {
    name: 'LemmaEditOccurrences',
    props: ['occurrences', 'containerOccurrences', 'references', 'objectType', 'objectId', 'lemmaId', 'dictionaryId', 'disableEdit'],
    data(){
      return {
        x_occurrences: this.occurrences,
        x_containerOccurrences: this.containerOccurrences,
        x_references: Utils.sortOccurrencesReferences(this.references, 'form'),
        showDetailsOnOccurrences: false,
        showDetailsOnReferences: false,
        selectedOccurrences: [],
        config: config,
      }
    },
    components: {
      LemmaEditOccurrence,
      LemmaEditReference
    },
    computed: {
      sortedOccurrences(){
        let occurrences = this.occurrences.concat(this.containerOccurrences)
        return Utils.sortOccurrencesReferences(occurrences, 'form')
      }
    },
    methods: {
      moveToLemma(selectedOccurrences, objectId){
        this.$emit('moveToLemma', selectedOccurrences.map(selected => selected.split("|")[1]), objectId)
        this.selectedOccurrences = []
      },
      moveToSenseProtospelling(selectedOccurrences, objectId){
        this.$emit('moveToSenseProtospelling', selectedOccurrences.map(selected => selected.split("|")[1]), objectId)
        this.selectedOccurrences = []
      },
      removeSelected(){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              this.selectedOccurrences.forEach(obj => {
                let occurrenceType = obj.split('|')[0]
                let occurrenceReferenceId = obj.split('|')[1]
                this.$store.dispatch('deleteOccurrenceReference', {
                  lemmaId: this.lemmaId,
                  occurrenceId: occurrenceReferenceId,
                  type: occurrenceType,
                  objectType: this.objectType,
                  objectId: this.objectId
                }).then(() => {
                  if (occurrenceType == 'reference'){
                    this.x_references = this.x_references.filter(reference => reference.id != occurrenceReferenceId)
                  }
                  else {
                    delete this.x_containerOccurrences[occurrenceReferenceId];
                  }
                  this.$forceUpdate();
                })
                if (occurrenceType == 'reference'){
                  this.$emit('removeReference', occurrenceReferenceId, this.objectId)
                }
                else {
                  this.$emit('removeOccurrence', occurrenceReferenceId, this.objectId)
                }
              })
              this.selectedOccurrences = []
            }
          })
      },
      showOccurrenceReferenceDetails(occurrence){
        this.$emit('showOccurrenceReferenceDetails', occurrence)
      },
      removeOccurrence(occurrenceId){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              this.$store.dispatch('deleteOccurrenceReference', {
                lemmaId: this.lemmaId,
                occurrenceId: occurrenceId,
                type: 'occurrence',
                objectType: this.objectType,
                objectId: this.objectId
              }).then(() => {
                delete this.x_containerOccurrences[occurrenceId];
                this.$forceUpdate();
              })
              this.$emit('removeOccurrence', occurrenceId, this.objectId)
            }
          })
      },
      removeContainerOccurrence(occurrenceId){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              this.$store.dispatch('deleteOccurrenceReference', {
                lemmaId: this.lemmaId,
                occurrenceId: occurrenceId,
                type: 'containeroccurrence',
                objectType: this.objectType,
                objectId: this.objectId
              }).then(() => {
                delete this.x_containerOccurrences[occurrenceId];
                this.$forceUpdate();
              })
              this.$emit('removeOccurrence', occurrenceId, this.objectId)
            }
          })
      },
      removeReference(referenceId){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              this.$store.dispatch('deleteOccurrenceReference', {
                lemmaId: this.lemmaId,
                occurrenceId: referenceId,
                type: 'reference',
                objectType: this.objectType,
                objectId: this.objectId
              }).then(() => {
                this.x_references = this.x_references.filter(reference => reference.id != referenceId)
                this.$forceUpdate();
              })
              this.$emit('removeReference', referenceId, this.objectId)
            }
          })
      },
      changeReference(oldReferenceId, newReference){
        this.$store.dispatch('changeOccurrenceReference', {
          lemmaId: this.lemmaId,
          oldReferenceId: oldReferenceId,
          newReferenceId: newReference.id,
          type: 'reference',
          objectType: this.objectType,
          objectId: this.objectId
        }).then(() => {
          this.x_references = this.x_references.filter(reference => reference.id != oldReferenceId)
          this.$forceUpdate();
          this.x_references.push(newReference)
        })
      },
      changeOccurrence(oldOccurrenceId, newOccurrence){
        this.$store.dispatch('changeOccurrenceReference', {
          lemmaId: this.lemmaId,
          oldOccurrenceId: oldOccurrenceId,
          newOccurrenceId: newOccurrence.id,
          type: 'occurrence',
          objectType: this.objectType,
          objectId: this.objectId
        }).then(() => {
          this.x_occurrences = this.x_occurrences.filter(occurrence => occurrence.id != oldOccurrenceId)
          this.$forceUpdate();
          this.x_occurrences.push(newOccurrence)
        })
      },
      changeContainerOccurrence(oldOccurrenceId, newOccurrence){
        this.$store.dispatch('changeOccurrenceReference', {
          lemmaId: this.lemmaId,
          oldOccurrenceId: oldOccurrenceId,
          newOccurrenceId: newOccurrence.id,
          type: 'containteroccurrence',
          objectType: this.objectType,
          objectId: this.objectId
        }).then(() => {
          this.x_containerOccurrences = this.x_containerOccurrences.filter(occurrence => occurrence.id != oldOccurrenceId)
          this.$forceUpdate();
          this.x_containerOccurrences.push(newOccurrence)
        })
      }
    }
  }
</script>
