<template>
  <div class="occurrence">
    <div v-if="isEdit && model.token == null" class="occurrence-form">
      <b-form-group
        id="occurrence-siglum-group"
        label="Siglum:"
        label-for="occurrence-siglum"
        v-if="config.editable"
      >
        <OccurrenceSelect
          :occurrence="model"
          @select="select"
          :forLemmaId="lemmaId"
          :objectType="type"
        />
      </b-form-group>

      <b-form-checkbox
        v-model="model.suppressed"
        class="mb-4 d-inline-block"
        size="sm"
        name="check-button"
        switch
        @change="changeSuppressed"
      >
        Suppressed
      </b-form-checkbox>

      <div class="text-right">
        <button class="btn-legam-xs btn-legam-red" @click="remove" v-if="disableEdit != true">
          <b-icon icon="trash-fill"></b-icon>
          Remove
        </button>
        <button class="btn-legam-xs" @click="isEdit = !isEdit">Cancel</button>
      </div>
    </div>

    <div v-else class="tooltip-box tooltip-box-w110">
      <div class="occurrence-text">
        <span v-if="model.suppressed">°</span>
        <OccurrenceDisplay :occurrence="model" :showDetails="showDetails" :type="type" />
        <span class="contextmenu">
          <button class="btn-legam-tooltip" @click="showDetailsModal">
            <b-icon icon="search"></b-icon>
            Pop-up view
          </button>
          <router-link :to="{name: 'OccurrenceDetails', params: {id: model.id}}" target="_blank" class="btn-legam-tooltip">
            <b-icon icon="search"></b-icon>
            Occurrence Details <span v-if="config.editable">(Edit)</span>
          </router-link>
          <router-link
            :to="{name: 'DocumentDetails', params: {id: model.token.document.id}}"
            target="_blank"
            class="btn-legam-tooltip"
            v-if="model.token && model.token.document"
          >
            <b-icon icon="search"></b-icon>
            Document Details
          </router-link>
          <div class="separator" v-if="disableEdit != true"></div>
          <button
            v-if="model.token == null && disableEdit != true"
            class="btn-legam-tooltip"
            @click="isEdit = true"
            :disabled="model._canBeEdited == false"
          >
            <b-icon icon="pencil-fill"></b-icon>
            Edit
          </button>
          <button class="btn-legam-tooltip" @click="remove" v-if="disableEdit != true">
            <b-icon icon="trash-fill"></b-icon>
            Remove
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .occurrence {
    margin-bottom: 5px;
  }

  .occurrence-text {
    padding: 2px 5px;
    cursor: pointer;
    border-radius: 3px;
  }

  span.not-empty:empty::before {
    content: '(!) Edit new occurrence';
    display: inline-block;
  }

  .occurrence-form {
    margin-left: 5px;
    padding: 8px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 17%);
  }

  .occurrence:hover > .occurrence-text {
    background-color: #d6d6d6;
  }
</style>

<script>
  import OccurrenceSelect from '@/components/OccurrenceSelect.vue'
  import OccurrenceDisplay from '@/components/Lemma/OccurrenceDisplay.vue'
  import config from '@/config.js'

  export default {
    name: 'LemmaEditOccurrence',
    props: ['occurrence', 'type', 'lemmaId', 'dictionaryId', 'showDetails', 'disableEdit'],
    data(){
      let model = this.occurrence
      let suppressedTypeAttribute = (this.type == 'protospelling' ? 'suppressedSpellingDictionaries' : 'suppressedSenseDictionaries')
      model.suppressed = (
        model[suppressedTypeAttribute] &&
        model[suppressedTypeAttribute].map(dictionary => dictionary.id).includes(this.dictionaryId)
      )
      return {
        isEdit: (this.occurrence.externalEvidence == null ? true : false),
        model: model,
        oldModelId: model.id,
        suppressedTypeAttribute: suppressedTypeAttribute,
        config: config
      }
    },
    components: {
      OccurrenceSelect,
      OccurrenceDisplay
    },
    methods: {
      edit(){
        this.isEdit = true
      },
      remove(event){
        event.preventDefault()
        this.$emit('remove', this.model.id)
      },
      showDetailsModal(event){
        event.preventDefault()
        this.$emit('showDetails', this.model)
      },
      changeSuppressed(){
        if (this.model.suppressed) {
          this.model[this.suppressedTypeAttribute].push({
            id: this.dictionaryId
          })
        }
        else {
          this.model[this.suppressedTypeAttribute] = this.model[this.suppressedTypeAttribute].filter(
            dictionary => dictionary.id != this.dictionaryId
          )
        }
        this.$store.dispatch('saveOccurrence', this.model).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
        })
      },
      select(){
        this.isEdit = false
      }
    }
  }
</script>
