<template>
  <span>
    <span
      v-if="sense.displayNumber"
      v-html="sense.displayNumber"
    ></span><span
      v-if="sense.lemmaSense && sense.lemmaSense.leftContext"
      v-html="sense.lemmaSense.leftContext"
    ></span> <span v-if="sense.definition">"<span
      class="text-green"
      v-html="sense.definition"
    ></span>"</span><span
      v-if="sense.paradefinition"
    > [<span v-html="sense.paradefinition"></span>]</span
    ><span
      v-if="sense.designation"
    > [<span v-html="sense.designation"></span>]</span
    > <span class="sense-ontology" v-if="sense.categories.length && config.editable" v-html="sense.categories.map(category => category.concept + ' - ' + category.fullCode).join(', ')"></span
    > <span
      v-if="sense.lemmaSense && sense.lemmaSense.rightContext"
      v-html="sense.lemmaSense.rightContext"
    ></span>
  </span>
</template>

<script>
  import config from '@/config.js'

  export default {
    name: 'SenseDisplay',
    props: ['sense'],
    data() {
      return {
        config: config,
      }
    }
  }
</script>
