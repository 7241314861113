<template>
  <div class="reference">
    <div v-if="isEdit && config.editable" class="reference-form">
      <b-form-group
        id="reference-siglum-group"
        label="Siglum:"
        label-for="reference-siglum"
      >
        <ReferenceSelect :reference="model" @select="select" :forLemmaId="lemmaId" />
      </b-form-group>

      <b-form-checkbox
        v-model="model.suppressed"
        class="mb-4 d-inline-block"
        size="sm"
        name="check-button"
        switch
        @change="changeSuppressed"
      >
        Suppressed
      </b-form-checkbox>

      <div class="text-right">
        <button class="btn-legam-xs btn-legam-red" @click="remove">
          <b-icon icon="trash-fill"></b-icon>
          Remove
        </button>
        <button class="btn-legam-xs" @click="isEdit = !isEdit">Cancel</button>
      </div>
    </div>

    <div v-else class="tooltip-box tooltip-box-w110">
      &nbsp;<span v-if="model.suppressed">°</span>
      <ReferenceDisplay :reference="model" :showDetails="showDetails" />
      <span class="contextmenu">
        <button
          class="btn-legam-tooltip"
          @click="isEdit = true"
          :disabled="model._canBeEdited == false"
          v-if="disableEdit != true"
        >
          <b-icon icon="pencil-fill"></b-icon>
          Edit
        </button>
        <button class="btn-legam-tooltip" @click="remove" v-if="disableEdit != true">
          <b-icon icon="trash-fill"></b-icon>
          Remove
        </button>
        <button class="btn-legam-tooltip" @click="showModalDetails">
          <b-icon icon="search"></b-icon>
          Show details
        </button>
        <router-link :to="{ name: 'ReferenceDetails', params: {id: model.id} }" target="_blank" class="btn-legam-tooltip">
          <b-icon icon="search"></b-icon>
          Details<span v-if="config.editable">/Edit</span>
        </router-link>
      </span>
    </div>
  </div>
</template>

<style scoped>
  .reference {
    margin-bottom: 5px;
  }

  .reference-text {
    padding: 2px 5px;
    cursor: pointer;
    border-radius: 3px;
  }

  span.not-empty:empty::before {
    content: '(!) Edit new reference';
    display: inline-block;
  }

  .reference-form {
    margin-left: 5px;
    padding: 8px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 17%);
  }

  .reference:hover > .reference-text {
    background-color: #d6d6d6;
  }
</style>

<script>
  import ReferenceSelect from '@/components/ReferenceSelect.vue'
  import ReferenceDisplay from '@/components/Lemma/ReferenceDisplay.vue'
  import config from '@/config.js'

  export default {
    name: 'LemmaEditReference',
    props: ['reference', 'type', 'lemmaId', 'dictionaryId', 'showDetails', 'disableEdit'],
    data(){
      let model = this.reference
      model.publicComment = (this.type == 'sense' ? model.publicCommentSense : model.publicCommentSpelling)
      let suppressedTypeAttribute = (this.type == 'protospelling' ? 'suppressedSpellingDictionaries' : 'suppressedSenseDictionaries')
      model.suppressed = (
        model[suppressedTypeAttribute] &&
        model[suppressedTypeAttribute].map(dictionary => dictionary.id).includes(this.dictionaryId)
      )
      return {
        config: config,
        isEdit: (this.reference.id == null ? true : false),
        model: model,
        suppressedTypeAttribute: suppressedTypeAttribute
      }
    },
    components: {
      ReferenceSelect,
      ReferenceDisplay
    },
    methods: {
      edit(){
        this.isEdit = true
      },
      select(reference){
        this.$emit('change', this.model.id, reference)
        this.isEdit = false
      },
      remove(event){
        event.preventDefault()
        this.$emit('remove', this.model.id)
      },
      showModalDetails(event){
        event.preventDefault()
        this.$emit('showDetails', this.model)
      },
      saveReference(event){
        event.preventDefault()
        this.$store.dispatch('saveReference', this.model).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.isEdit = false
        })
      },
      changeSuppressed(){
        if (this.model.suppressed) {
          this.model[this.suppressedTypeAttribute].push({
            id: this.dictionaryId
          })
        }
        else {
          this.model[this.suppressedTypeAttribute] = this.model[this.suppressedTypeAttribute].filter(
            dictionary => dictionary.id != this.dictionaryId
          )
        }
        this.$store.dispatch('saveReference', this.model).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
        })
      },
    }
  }
</script>
