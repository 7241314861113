<template>
  <div class="lemmaDetails">
    <div v-if="loading || !lemma">
      Loading..
    </div>
    <article v-else>
      <b-row>
        <b-col v-if="!hideTitle">
          <h3 class="mb-4" v-html="lemmaDisplay"></h3>
        </b-col>
        <b-col class="mt-1 text-right">
          <!-- <b-form-checkbox
            v-if="lemma.trees"
            @change="saveLemmaTree"
            v-model="lemma.trees[0].checked"
            class="mb-4 d-inline-block"
            size="sm"
            name="check-button"
            switch
            :disabled="!$store.getters.checkRole('legam.dict.lemma.modify')"
          >
            Checked
          </b-form-checkbox> -->
          <span class="btn-legam-xs btn-legam-xxs ml-2" v-if="showClose" @click="closeLemmaDetails">
            <b-icon icon="x"></b-icon>
            Close
          </span>
          <button
            v-if="$store.getters.checkRole('legam.dict.lemma.delete')"
            @click="deleteItem"
            class="btn-legam-xs mb-2 btn-danger"
          >
            <b-icon icon="trash2-fill"></b-icon>
            Delete lemma
          </button>
        </b-col>
      </b-row>
      <b-form-checkbox
        v-if="config.editable"
        v-model="lemma.isReconstructed"
        name="check-button"
        switch
        class="mt-4 mb-3"
        size="sm"
        @change="saveLemma"
        :disabled="!$store.getters.checkRole('legam.dict.lemma.modify')"
      >
        Is reconstructed
      </b-form-checkbox>

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 class="text-left">
              {{ $t('Etymology') }}
              <span class="when-open float-right">-</span>
              <span class="when-closed float-right">+</span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
            <b-card-body>
              <LemmaEditEtymology
                v-bind:etymologies="lemma.etymologies"
                @update="updateEtymologies"
                :lemmaId="lemma.id"
                :disableEdit="!$store.getters.checkRole('legam.dict.lemma.modify') || !config.editable"
              />
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 class="text-left">
              {{ $t('RedactedProtospellings') }}
              <span class="when-open float-right">-</span>
              <span class="when-closed float-right">+</span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
            <b-card-body>
              <LemmaEditProtospelling
                v-bind:lemma="lemma"
                :isDocLing="isDocLing"
                @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
                @updateProtospellings="updateProtospellings"
                @addOccurrencesReferencesToProtospelling="addOccurrencesReferencesToProtospelling"
                @removeOccurrencesReferences="removeOccurrencesReferences"
                :disableEdit="!$store.getters.checkRole('legam.dict.lemma.modify') || !config.editable"
                :dictionaryId="dictionaryId"
                v-if="lemma.protospellings && lemma.tokens"
              />
              <div v-else>
                <b-container fluid="xxl">
                  <b-row class="justify-content-md-center">
                      <b-col class="mt-1">
                        <div class="spinner-border m-1" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </b-col>
                  </b-row>
                </b-container>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 class="text-left">
              {{ $t('Senses') }}
              <span class="when-open float-right">-</span>
              <span class="when-closed float-right">+</span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
            <b-card-body>
              <LemmaEditSense
                v-bind:lemma="lemma"
                @showOccurrenceReferenceDetails="showOccurrenceReferenceDetails"
                @addOccurrencesReferencesToSense="addOccurrencesReferencesToSense"
                @removeOccurrencesReferences="removeOccurrencesReferences"
                :disableEdit="!$store.getters.checkRole('legam.dict.lemma.modify') || !config.editable"
                :dictionaryId="dictionaryId"
                v-if="lemma.senses && lemma.tokens"
              />
              <span v-else>Loading...</span>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div v-if="config.editable">
        <hr>
        <b-form-group
          id="etymon-form-group"
          label="Etymon:"
          label-for="etymon-form"
        >
          <LemmaSelect
            idClass="etymon"
            :lemma="lemma.etymon"
            @select="selectEtymon"
            v-model="lemma.etymon"
            v-if="$store.getters.checkRole('legam.dict.lemma.modify')"
          />
          <span v-else-if="lemma.etymon" v-html="lemma.language.name" class="text-bold"></span>
        </b-form-group>

        <b-form-group
          id="comment-group"
          label="Internal Comment:"
          label-for="comment"
        >
          <b-form-textarea
            id="comment"
            v-model="lemma.comment"
            placeholder="Internal Comment"
            rows="3"
            max-rows="6"
            @change="saveLemma"
            :disabled="!$store.getters.checkRole('legam.dict.lemma.modify')"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="public-comment-group"
          label="Supplementary Evidence:"
          label-for="public-comment"
        >
          <b-form-textarea
            id="public-comment"
            v-model="lemma.publicComment"
            placeholder="Supplementary Evidence"
            rows="3"
            max-rows="6"
            @change="saveLemma"
            :disabled="!$store.getters.checkRole('legam.dict.lemma.modify')"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="lemma-form-group"
          label="Lemma Form:"
          label-for="lemma-form"
        >
          <b-form-input
            id="lemma-form"
            v-model="lemma.lemmaForm"
            placeholder="Lemma Form"
            @change="saveLemma"
            :disabled="!$store.getters.checkRole('legam.dict.lemma.modify')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="etymon-form-group"
          label="Etymon:"
          label-for="etymon-form"
        >
          <LemmaSelect
            :lemma="lemma.etymon"
            @select="selectEtymon"
            v-model="lemma.etymon"
            v-if="$store.getters.checkRole('legam.dict.lemma.modify')"
          />
          <span v-else-if="lemma.etymon" v-html="lemma.language.name" class="text-bold"></span>
        </b-form-group>

        <b-form-group
          id="part-of-speech-group"
          label="Part of speech:"
          label-for="part-of-speech"
        >
          <PartOfSpeechSelect
            :selected="lemma.partofspeeches"
            v-if="$store.getters.checkRole('legam.dict.lemma.modify')"
            @select="selectPartOfSpeech"
          />
          <span v-else-if="lemma.partofspeeches" class="text-bold">
            {{ lemma.partofspeeches.map(partofspeeche => partofspeeche.name).join(", ") }}
          </span>
        </b-form-group>

        <b-form-group
          id="language-group"
          label="Language:"
          label-for="language"
        >
          <LanguageSelect
            :language="lemma.language"
            @select="selectLanguage"
            v-if="$store.getters.checkRole('legam.dict.lemma.modify')"
          />
          <span v-else-if="lemma.language" v-html="lemma.language.name" class="text-bold"></span>
        </b-form-group>

        <b-form-group
          id="scripta-group"
          label="Scripta:"
          label-for="scripta"
        >
          <ScriptaSelect
            :languageCode="lemma.languageCode"
            :selected="lemma.scriptas"
            @select="selectScripta"
            v-if="$store.getters.checkRole('legam.dict.lemma.modify')"
          />
          <span v-else-if="lemma.scriptas" class="text-bold">
            {{ lemma.scriptas.map(scripta => scripta.name).join(", ") }}
          </span>
        </b-form-group>
      </div>
    </article>

    <b-modal :id="'modal-occurrence-details' + componentUUID" size="lg" ok-only ok-title="Close" title="Occurrence or reference details">
      <OccurrenceReferenceDetailsModal :occurrence_reference="occurrence_reference" />
    </b-modal>
  </div>
</template>

<style scoped>
  * >>> ul.occurrences-ul {
    list-style-type: '';
    padding-left: 0px;
  }

  * >>> .occurrences-ul .custom-checkbox,
  * >>> .occurrences-ul .custom-control-label {
    width: 100%;
  }

  li.occurrence {
    cursor: pointer;
  }

  .searchSense,
  .searchProtospelling {
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 15px 4px;
    margin-bottom: 20px;
  }
</style>

<script>
  import Vue from 'vue'
  import router from '@/router'

  import LemmaEditEtymology from '@/components/Lemma/EditEtymology.vue'
  import LemmaEditProtospelling from '@/components/Lemma/EditProtospelling.vue'
  import LemmaEditSense from '@/components/Lemma/EditSense.vue'
  import OccurrenceReferenceDetailsModal from '@/components/Lemma/OccurrenceReferenceDetailsModal.vue'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'

  import PartOfSpeechSelect from '@/components/Dictionary/PartOfSpeechSelect.vue'
  import ScriptaSelect from '@/components/Main/ScriptaSelect.vue'
  import LanguageSelect from '@/components/Main/LanguageSelect.vue'
  import config from '@/config.js'


  export default {
    name: 'LemmaEditDetails',
    props: ['lemmaId', 'lemmaObj', 'showCloseButton', 'dictionaryId', 'hideTitle'],
    components: {
      LemmaEditEtymology,
      LemmaEditProtospelling,
      LemmaEditSense,
      OccurrenceReferenceDetailsModal,
      PartOfSpeechSelect,
      ScriptaSelect,
      LanguageSelect,
      LemmaSelect
    },
    data(){
      let _lemma = this.lemmaObj
      if (_lemma && !_lemma.protospellingTree){
        _lemma.protospellingTree = {id: null, children: []}
      }
      return {
        lemma: _lemma,
        loading: true,
        showSenseBox: {},
        showProtospellingBox: {},
        showClose: (this.showCloseButton || this.showCloseButton === undefined ? true : false),
        occurrence_reference: null,
        componentUUID: this.$store.getters.getUUID(),
        config: config,
      }
    },
    computed: {
      lemmaDisplay(){
        let retval = ''
        if (this.lemma){
          let prefix = ''
          if (this.lemma.scriptas.length){
            prefix = `<small>${this.lemma.scriptas.map(scripta => scripta.code).join(', ')} </small>`
          }
          if (this.lemma.isReconstructed){
            prefix = `${prefix}*`
          }
          let sufix = ''
          if (this.lemma.partofspeeches.length){
            sufix = `<small> ${this.lemma.partofspeeches.map(partofspeeche => partofspeeche.name).join(', ')}</small>`
          }
          let etymon = ''
          if (this.lemma.treeParent){
            etymon = `<small> [${this.lemma.treeParent.display}]</small>`
          }
          if (this.lemma.etymon){
            etymon = `<small> [${this.lemma.etymon.display}]</small>`
          }
          retval = `${prefix}${this.lemma.lemmaForm}${sufix}${etymon}`
        }
        return retval
      },
      isDocLing(){
        return (this.lemma
          && this.lemma.articleDictionaryAcronym
          && this.lemma.articleDictionaryAcronym.toLowerCase().substring(0, 7) == "docling") ? true : false
      }
    },
    mounted(){
      if (!this.lemma){
        this.loadLemma()
      }
      else {
        // this.checkSpellingsSenses()
        this.loadLemmaTokens()
        this.loading = false
      }
    },
    watch: {
      'lemmaId'(){
        this.loading = true
        this.loadLemma()
      },
      'lemma'(){
        this.loading = false
        if (this.lemma && !this.lemma.protospellingTree){
          this.lemma.protospellingTree = {id: null, children: []}
        }
      }
    },
    methods: {
      getLemmaId(){
        return this.lemma ? this.lemma.id : this.lemmaId
      },
      // checkSpellingsSenses(){
      //   console.log("Check senses/spellings")
      //   if (this.lemma && !this.lemma.senses){
      //     console.log("Load senses", this.lemmaId)
      //     this.$store.dispatch('loadLemmaSenses', this.getLemmaId()).then((lemma) => {
      //       console.log("R", lemma)
      //       this.lemma = lemma
      //     })
      //   }
      // },
      loadLemmaTokens(){
        if (config.editable || this.lemma.tokenCount > 0) {
          this.$store.dispatch('loadLemmaTokens', this.getLemmaId()).then((tokens) => {
            this.lemma['tokens'] = tokens['tokens']
            this.lemma['tokensDictIndex'] = tokens['tokensDictIndex']
            this.$forceUpdate()
          })
        }
        else {
          this.lemma['tokens'] = []
          this.lemma['tokensDictIndex'] = {}
        }
      },
      loadLemma(){
        this.$store.dispatch('loadLemma', this.lemmaId).then((lemma) => {
          this.lemma = lemma
          // this.checkSpellingsSenses()
          this.loadLemmaTokens()
        })
      },
      deleteItem(){
        if (this.lemma.articleId){
          this.$store.dispatch('addNotification', {
            type: 'warning',
            text: `First remove lemma from article`
          })
        }
        else {
          this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
            if (value){
              this.$store.dispatch('deleteLemma', this.getLemmaId()).then(() => {
                router.push(`/lemmas`)
              })
            }
          })
        }
      },
      showOccurrenceReferenceDetails(occurrence_reference){
        this.occurrence_reference = occurrence_reference
        this.$bvModal.show(`modal-occurrence-details${this.componentUUID}`)
      },
      saveLemma(){
        this.$store.dispatch('saveLemma', this.lemma).then(() => {
          this.$emit('update')
        })
      },
      selectPartOfSpeech(partofspeeches){
        this.lemma['partofspeeches'] = partofspeeches
        this.saveLemma()
      },
      selectScripta(scriptas){
        this.lemma['scriptas'] = scriptas
        this.saveLemma()
      },
      selectLanguage(language){
        this.lemma.languageCode = null
        this.lemma.language = null
        if (language){
          this.lemma.languageCode = language.code
          this.lemma.language = language
        }
        this.saveLemma()
      },
      selectEtymon(lemma){
        if (lemma){
          this.lemma.etymon = lemma
        }
        this.saveLemma()
      },
      saveLemmaTree(){
        this.$store.dispatch('saveLemmaTree', this.lemma)
      },
      removeOccurrencesReferences(occurrences_references, selectedLemma){
          let selectedOccurrences = occurrences_references.map(occurrence_reference_id => {
            let type = (occurrence_reference_id in this.lemma.referencesDictIndex ? 'reference' : 'occurrence')
            return [occurrence_reference_id, type]
          })

          this.$store.dispatch('moveOccurrencesReferenceToLemma', {
            lemmaId: selectedLemma.id,
            occurrencesReferences: selectedOccurrences
          }).then(() => {
            this.lemma.occurrences = this.lemma.occurrences.filter(occurrence => !occurrences_references.includes(occurrence.occurrenceId))
            this.lemma.occurrences = this.lemma.occurrences.filter(occurrence => !occurrences_references.includes(occurrence.containerOccurrenceId))
            this.lemma.references = this.lemma.references.filter(reference => !occurrences_references.includes(reference.id))
          })
      },
      async addOccurrencesReferencesToSense(occurrences_references, sense){
        if (!(sense.id in this.lemma.senses)){
          await this.$store.dispatch('loadSense', {
            senseId: sense.id,
            lemmaId: this.lemma.id
          }).then(async (_sense) => {
            if (!_sense.lemmaSense){
              _sense.lemmaSense = {
                lemmaId: this.lemma.id,
                senseId: sense.id,
                checked: false,
                leftContext: '',
                isLeftValid: false,
                rightContext: '',
                isRightValid: false
              }

              await this.$store.dispatch('addSenseToLemma', {
                lemmaId: this.lemma.id,
                senseId: sense.id,
              }).then(() => {
                this.lemma.senses[sense.id] = _sense
                if (!this.lemma.senseTree){
                  this.lemma.senseTree = {id: null, children: []}
                }
                this.lemma.senseTree.children.push({id: sense.id, children: []})
              })
            }
            else if (!(sense.id in this.lemma.senses)) {
              this.lemma.senses[sense.id] = _sense
              if (!this.lemma.senseTree){
                this.lemma.senseTree = {id: null, children: []}
              }
              this.lemma.senseTree.children.push({id: sense.id, children: []})
            }
          })
        }

        for (let occurrence_reference_id of occurrences_references){
          if (occurrence_reference_id in this.lemma.occurrencesDictIndex){
            let occurrenceIndex = this.lemma.occurrencesDictIndex[occurrence_reference_id]
            let occurrence = this.lemma.occurrences[occurrenceIndex]
            this._connectOccurrenceToSense(sense, occurrence.occurrence)
          }
          else if (occurrence_reference_id in this.lemma.containerOccurrencesDictIndex){
            let occurrenceIndex = this.lemma.containerOccurrencesDictIndex[occurrence_reference_id]
            let occurrence = this.lemma.occurrences[occurrenceIndex]
            this._connectOccurrenceToSense(sense, occurrence.containerOccurrence)
          }
          else {
            let referenceIndex = this.lemma.referencesDictIndex[occurrence_reference_id]
            let reference = this.lemma.references[referenceIndex]
            this._connectReferenceToSense(sense, reference)
          }
        }
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Occurrence and references added`
        })
      },
      _connectReferenceToSense(sense, reference){
        this.$store.dispatch('addReferenceToSPS', {
          lemmaId: this.lemma.id,
          referenceId: reference.id,
          senseId: sense.id
        }).then(() => {
          this.lemma.senses[sense.id].references.push(reference)
          reference.senseIds.push(sense.id)
          // if (reference.surfaceForm){
          //   let surfaceFormKey = reference.surfaceForm
          //   if (!this.lemma.senses[sense.id].occurrencesSurfaceFormGrouped[surfaceFormKey]){
          //     this.lemma.senses[sense.id].occurrencesSurfaceFormGrouped[surfaceFormKey] = []
          //   }
          //   this.lemma.senses[sense.id].occurrencesSurfaceFormGrouped[surfaceFormKey].push([
          //     reference.id,
          //     'reference'
          //   ])
          // }
        })
      },
      _connectOccurrenceToSense(sense, occurrence){
        // Connect occurrence to PS
        this.$store.dispatch('addOccurrenceToSPS', {
          lemmaId: this.lemma.id,
          occurrenceId: occurrence.id,
          senseId: sense.id
        }).then(() => {
          this.lemma.senses[sense.id] = {
            'occurrences': [],
            'containerOccurrences': [],
            // 'occurrencesSurfaceFormGrouped': {},
            ...this.lemma.senses[sense.id]
          }
          occurrence.senseId = sense.id

          if (occurrence._occurrenceType == 'containeroccurrence'){
            this.lemma.senses[sense.id].containerOccurrences.push(occurrence)
          }
          else {
            // occurrences
            this.lemma.senses[sense.id].occurrences.push(occurrence)
          }
          // let surfaceFormKey = null
          // if (occurrence.externalEvidence && occurrence.externalEvidence.surfaceForm){
          //   surfaceFormKey = occurrence.externalEvidence.surfaceForm
          // }
          // else if (occurrence.token){
          //   surfaceFormKey = occurrence.token.form
          // }
          // if (surfaceFormKey){
          //   if (!this.lemma.senses[sense.id].occurrencesSurfaceFormGrouped[surfaceFormKey]){
          //     this.lemma.senses[sense.id].occurrencesSurfaceFormGrouped[surfaceFormKey] = []
          //   }
          //   this.lemma.senses[sense.id].occurrencesSurfaceFormGrouped[surfaceFormKey].push([
          //     occurrence.id,
          //     occurrence._occurrenceType
          //   ])
          // }
          this.showSenseBox[occurrence.id] = false
          // Use a :key for the component and reset the key for update data
          // this.index++
        })
      },
      updateEtymologies(etymologies){
        this.lemma.etymologies = etymologies
      },
      async addOccurrencesReferencesToProtospelling(occurrences_references, protospellingId){
        if (!(protospellingId in this.lemma.protospellings)){
          await this.$store.dispatch('loadProtospelling', {
            protospellingId: protospellingId,
            lemmaId: this.lemma.id
          }).then(async (_protospelling) => {
            if (!_protospelling.lemmaProtospelling){
              _protospelling.lemmaProtospelling = {
                checked: false,
                isLeftValid: null,
                isRightValid: null,
                leftContext: '',
                lemmaId: this.lemma.id,
                linguisticSpecification: '',
                protospellingId: protospellingId,
                rightContext: '',
              }
              if (!('occurrencesSurfaceFormGrouped' in _protospelling)){
                _protospelling['occurrencesSurfaceFormGrouped'] = {}
              }

              await this.$store.dispatch('addProtospellingToLemma', {
                lemmaId: this.lemma.id,
                protospellingId: protospellingId,
              }).then(() => {
                this.lemma.protospellings[protospellingId] = _protospelling
                if (!this.lemma.protospellingTree){
                  this.lemma.protospellingTree = {id: null, children: []}
                }
                this.lemma.protospellingTree.children.push({id: protospellingId, children: []})
              })
            }
            else if (!(protospellingId in this.lemma.protospellings)){
              this.lemma.protospellings[protospellingId] = _protospelling
              if (!this.lemma.protospellingTree){
                this.lemma.protospellingTree = {id: null, children: []}
              }
              this.lemma.protospellingTree.children.push({id: protospellingId, children: []})
            }
          })
        }

        for (let occurrence_reference_id of occurrences_references){
          if (occurrence_reference_id in this.lemma.occurrencesDictIndex){
            let occurrenceIndex = this.lemma.occurrencesDictIndex[occurrence_reference_id]
            let occurrence = this.lemma.occurrences[occurrenceIndex]
            this._connectOccurrenceToProtospelling(protospellingId, occurrence.occurrence)
          }
          else if (occurrence_reference_id in this.lemma.containerOccurrencesDictIndex){
            let occurrenceIndex = this.lemma.containerOccurrencesDictIndex[occurrence_reference_id]
            let occurrence = this.lemma.occurrences[occurrenceIndex]
            this._connectOccurrenceToProtospelling(protospellingId, occurrence.containerOccurrence)
          }
          else {
            let referenceIndex = this.lemma.referencesDictIndex[occurrence_reference_id]
            let reference = this.lemma.references[referenceIndex]
            this._connectReferenceToProtospelling(protospellingId, reference)
          }
        }
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Occurrence and references added`
        })
      },
      _connectReferenceToProtospelling(protospellingId, reference){
        this.$store.dispatch('addReferenceToSPS', {
          lemmaId: this.lemma.id,
          referenceId: reference.id,
          protospellingId: protospellingId
        }).then(() => {
          this.lemma.protospellings[protospellingId].references.push(reference)
          reference.protospellingIds.push(protospellingId)
          if (reference.surfaceForm){
            let surfaceFormKey = reference.surfaceForm
            if (!(surfaceFormKey in this.lemma.protospellings[protospellingId].occurrencesSurfaceFormGrouped)){
              this.lemma.protospellings[protospellingId].occurrencesSurfaceFormGrouped[surfaceFormKey] = []
            }
            this.lemma.protospellings[protospellingId].occurrencesSurfaceFormGrouped[surfaceFormKey].push(
              [reference.id, 'reference']
            )
          }
        })
      },
      _connectOccurrenceToProtospelling(protospellingId, occurrence){
        // Connect occurrence to PS
        this.$store.dispatch('addOccurrenceToSPS', {
          lemmaId: this.lemma.id,
          occurrenceId: occurrence.id,
          protospellingId: protospellingId
        }).then(() => {
          this.lemma.protospellings[protospellingId] = {
            'occurrences': [],
            'containerOccurrences': [],
            'occurrencesSurfaceFormGrouped': {},
            ...this.lemma.protospellings[protospellingId]
          }
          occurrence.protospellingId = protospellingId

          if (occurrence._occurrenceType == 'containeroccurrence'){
            this.lemma.protospellings[protospellingId].containerOccurrences.push(occurrence)
          }
          else {
            // occurrences
            this.lemma.protospellings[protospellingId].occurrences.push(occurrence)
          }
          let surfaceFormKey = null
          if (occurrence.externalEvidence && occurrence.externalEvidence.surfaceForm){
            surfaceFormKey = occurrence.externalEvidence.surfaceForm
          }
          else if (occurrence.token){
            surfaceFormKey = occurrence.token.form
          }
          if (surfaceFormKey){
            if (!this.lemma.protospellings[protospellingId].occurrencesSurfaceFormGrouped[surfaceFormKey]){
              this.lemma.protospellings[protospellingId].occurrencesSurfaceFormGrouped[surfaceFormKey] = []
            }
            this.lemma.protospellings[protospellingId].occurrencesSurfaceFormGrouped[surfaceFormKey].push(
              [occurrence.id, occurrence._occurrenceType]
            )
          }
          this.showProtospellingBox[occurrence.id] = false
          // Use a :key for the component and reset the key for update data
          // this.index++
        })
      },
      showAddSenseBox(occurrenceId){
        Vue.set(this.showSenseBox, occurrenceId, true)
      },
      showAddProtospellingBox(occurrenceId){
        Vue.set(this.showProtospellingBox, occurrenceId, true)
      },
      closeLemmaDetails(){
        this.$emit('close')
      },
      updateProtospellings(protospellings, protospellingTree){
        this.lemma.protospellings = protospellings
        this.lemma.protospellingTree = protospellingTree
      }
    }
  }
</script>
