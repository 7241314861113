<template>
  <div class="protospellings">
    <b-tabs content-class="mt-3 ml-2" class="legam-tree-list-tabs no-mt">
      <b-tab title="Tree" active>
        <ul class="protospelling-ul" v-if="protospellingTree">
            <li
              v-for="protospelling in protospellingTree"
              :key="protospelling.id"
              class="tooltip-box tooltip-box-w110"
              :data-tree-level="protospelling.level"
            >
              <div class="li-content">
                <span @click="selectProtospelling($event, protospelling)" >
                  <ProtospellingDisplay :protospelling="protospelling" />&nbsp;
                </span>
              </div>
            </li>
        </ul>
      </b-tab>
      <b-tab title="Search">
        <b-container fluid="xxl">
          <b-row>
            <b-col>
              <!-- <span href="#" v-b-modal.modal-new-protospelling style="float: right">Create new protospelling</span> -->
              <b-form-group
                class="protospelling-group"
                label="Protospelling"
                label-for="protospelling"
              >
                <ProtospellingSelect @select="select" @selectNew="selectNew" :protospelling="protospelling" :key="indexProtospelling" />
              </b-form-group>
              <b-modal id="modal-new-protospelling" title="New protospelling" ok-title="Save" @ok="saveProtospelling" size="lg">
                <ProtospellingNew :protospelling="protospelling" />
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-form-group
                class="protospelling-left-context-group"
                label="Left context"
                label-for="protospelling-left-context"
              >
                <b-form-textarea
                  class="protospelling-left-context"
                  v-model="protospelling.lemmaProtospelling.leftContext"
                  placeholder="Left context"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-form-checkbox
                  v-model="protospelling.lemmaProtospelling.isLeftValid"
                  size="sm"
                  class="mt-1"
                  name="check-button"
                  switch
                >
                  Valid
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                class="protospelling-right-context-group"
                label="Right context"
                label-for="protospelling-right-context"
              >
                <b-form-textarea
                  class="protospelling-right-context"
                  v-model="protospelling.lemmaProtospelling.rightContext"
                  placeholder="Right context"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-form-checkbox
                  v-model="protospelling.lemmaProtospelling.isRightValid"
                  size="sm"
                  class="mt-1"
                  name="check-button"
                  switch
                >
                  Valid
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                class="protospelling-linguistic-specification-group"
                label="Linguistic specification"
                label-for="protospelling-linguistic-specification"
              >
                <b-form-textarea
                  class="protospelling-linguistic-specification"
                  v-model="protospelling.lemmaProtospelling.linguisticSpecification"
                  placeholder="Linguistic specification"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style scoped>
  .results-ul {
    list-style-type: '';
    padding-left: 10px;
  }
  li[data-tree-level="2"] {
    margin-left: 10px;
  }

  li[data-tree-level="3"] {
    margin-left: 20px;
  }

  li[data-tree-level="4"] {
    margin-left: 30px;
  }

  li[data-tree-level="5"] {
    margin-left: 40px;
  }

  ul.protospelling-ul {
    list-style-type: '';
    padding-left: 10px;
  }

  ul li div.li-content {
    padding: 4px 8px;
    cursor: pointer;
  }
</style>

<script>
  import ProtospellingNew from '@/components/ProtospellingNew.vue'
  import ProtospellingSelect from '@/components/Dictionary/ProtospellingSelect.vue'
  import HelpStore from '@/store/helpstore.js'
  import ProtospellingDisplay from '@/components/Lemma/ProtospellingDisplay.vue'

  export default {
    name: 'ProtospellingAutocomplete',
    props: ['occurrence', 'allowNew', 'exclude', 'protospellingTree'],
    data() {
      return {
        indexProtospelling: 0,
        protospelling: {
          id: null,
          spelling: '',
          lemmaProtospelling: {
            leftContext: '',
            isLeftValid: false,
            rightContext: '',
            isRightValid: false,
            linguisticSpecification: '',
          },
        }
      }
    },
    components: {
      ProtospellingNew,
      ProtospellingDisplay,
      ProtospellingSelect,
    },
    updated(){
      HelpStore.item = this.protospelling
    },
    methods: {
      selectProtospelling(event, protospelling){
        event.preventDefault()
        this.$emit('select', protospelling, this.occurrence)
      },
      select(protospelling){
        this.protospelling.id = protospelling.id
        this.protospelling.spelling = protospelling.spelling
        HelpStore.item = this.protospelling
      },
      selectNew(spelling){
        this.protospelling.spelling = spelling
        this.$bvModal.show('modal-new-protospelling')
      },
      saveProtospelling(){
        this.$store.dispatch('createProtospelling', HelpStore.item).then((response) => {
          this.protospelling.id = response.data.id
          this.protospelling.spelling = response.data.spelling
          this.indexProtospelling++
          HelpStore.item = this.protospelling
        })
      }
    }
  }
</script>
