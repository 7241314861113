<template>
  <span>
    <span
      v-if="protospelling.lemmaProtospelling && protospelling.lemmaProtospelling.linguisticSpecification"
      v-html="protospelling.lemmaProtospelling.linguisticSpecification"
    ></span> <span
      v-if="protospelling.lemmaProtospelling && protospelling.lemmaProtospelling.leftContext"
      v-html="protospelling.lemmaProtospelling.leftContext"
    ></span> <span
      class="not-empty text-italic text-green"
      v-html="protospelling.spelling"
    ></span> <span
      v-if="protospelling.lemmaProtospelling && protospelling.lemmaProtospelling.rightContext"
      v-html="protospelling.lemmaProtospelling.rightContext"
    ></span>
  </span>
</template>

<script>
  export default {
    name: 'ProtospellingDisplay',
    props: ['protospelling']
  }
</script>
