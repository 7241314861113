<template>
  <div class="toggleElement">
    <button class="toggleButton" @click="show = !show">
      <span v-if="show">-</span>
      <span v-else>+</span>
    </button>
    <!-- <router-link v-if="dataJson.to" :to="{ name: dataJson.to.name, params: { id: dataJson.to.id }}"> {{ dataJson.title }}</router-link> -->
    <span>{{ dataJson.title }}</span>
    <div v-if="dataJson.isSafe" v-show="show" class="detailsBox" v-html="dataJson.text"></div>
    <div v-else v-show="show" class="detailsBox">
      <!-- {{ dataJson.text }} -->
      <div v-if="Object.keys(dataJson.protospellings).length" class="protospelling-box">
        <h6 class="mt-3">Protospelling</h6>
        <span v-for="protospelling in dataJson.protospellings" :key="protospelling.id" v-html="protospelling.publicComment">
        </span>
      </div>
    </div>

  </div>
</template>

<style scoped>
  .toggleButton {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    display: inline-block;
    line-height: 19px;
    margin-right: 7px;
    text-decoration: none;
    border: 1px solid #e8e8e8;
  }
  .detailsBox {
    margin-left: 27px;
  }
  .protospelling-box {
    margin-bottom: 20px;
  }
  .protospelling-box span:not(:last-child):after {
    content: ", ";
  }
  h6 {
    font-size: 10px;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  export default {
    name: 'ExpandElement',
    props: ['dataJson', 'defaultVisible'],
    data() {
      return {
        show: this.defaultVisible == true ? true : false,
        isVisible: false
      }
    }
  }
</script>
